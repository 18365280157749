import React, {Component} from "react";
import {Button, Form, Input, Select, Switch} from "antd";
import Axios from "axios";
import Notify from "../utils/Notifications";
import {env_var} from "../../environment";

const {Option} = Select;
const {TextArea} = Input;

class PlaygroundView extends Component {

    state = {
        buttonLoading: false,
        asgnOnly: false,
        endpoint: '',
        method: 'POST',
        privateKey: 'f7dd9402-8432-4a30-a072-941722bf24fb',
        disableTab: true,
        response: '',
        payload: "{\n\t\"ak\": \"283dba8d-e43e-4b38-88fd-139b27a89a87\",\n\t\"email\": \"mainaaak@no.no\",\n\t\"subdomain\": \"zomato\",\n\t\"ts\": \""+Math.floor(Date.now()/1000)+"\"\n}"
    }

    layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 12}
    };

    tailLayout = {
        wrapperCol: {offset: this.layout.labelCol.span, span: 8}
    };

    changeHandler = (e) => {

        if (e !== 'GET' && e !== 'POST' && e !== true && e !== false) {
            this.setState({
                [e.target.id]: e.target.value
            });
        } else if (e === true || e === false) {
            this.setState({
                asgnOnly: e
            })
        } else {
            this.setState({
                method: e
            })
        }
    }

    submitRequest = async (e) => {
        const {endpoint, method, privateKey, payload, asgnOnly} = this.state;
        e.preventDefault();

        this.setState({
            buttonLoading: true
        });

        const promise = await Axios.post(
            asgnOnly ? env_var.API_BASE_URL + '/api/playground/asgn' : env_var.API_BASE_URL + '/api/playground/asgn?type=auto',
            {
                endpoint: endpoint,
                payload: JSON.parse(payload),
                method: method,
                privateKey: privateKey
            },
            {
                validateStatus: () => true
            }
        );

        if (promise.status === 200) {
            this.setState({
                response: promise.data,
                buttonLoading: false
            });
            this.props.disableTab(false);
            this.props.updateResponse(promise);
            Notify.responseReceivedNotification();
        } else {
            alert(promise.status + '\n' + promise.data);
            this.setState({
                buttonLoading: false
            })
        }
    }

    render() {

        const {method, buttonLoading} = this.state;

        return (
            <Form
                {...this.layout}
                name="basic"
                initialValues={{emailId: ''}}
                onFinish={this.makeRequest}
                onFinishFailed={this.onFinishFailed}
                style={{marginTop: 10}}
            >
                <Form.Item
                    label="Endpoint"
                    name="endpoint"
                    // initialValue={emailId}
                    rules={[{required: true, message: 'Please input your emailId!'}]}
                >
                    <Input id={"endpoint"} allowClear={true} placeholder={"https://certification.mettl.com/api/v2/**"}
                           type={"url"} onChange={this.changeHandler} autoComplete={"off"}/>

                </Form.Item>

                <Form.Item
                    label="Private Key"
                    name="privateKey"
                    initialValue={this.state.privateKey}
                    rules={[{required: true, message: 'Please input your emailId!'}]}
                >
                    <Input id={"privateKey"} allowClear={true} placeholder={"f7dd9402-8432-4a30-a072-941722bf24fb"}
                           type={"text"} onChange={this.changeHandler} autoComplete={"off"}/>

                </Form.Item>

                <Form.Item
                    label={"Method"}
                    name={"method"}
                    rules={[{required: false, message: 'Please input the endpoint type!'}]}
                >
                    <Select defaultValue={method} style={{width: 120}} onChange={this.changeHandler} id={"method"}>
                        <Option value="GET" disabled={false}>GET</Option>
                        <Option value="POST">POST</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Write your JSON here"
                    name="json"
                    initialValue={this.state.payload}
                    rules={[{required: true, message: 'Please input your JSON'}]}
                >
                    <TextArea placeholder={this.state.payload} allowClear={true} onChange={this.changeHandler}
                              autoSize={{minRows: 10, maxRows: 10}} disabled={false} id={"payload"}/>
                </Form.Item>

                <Form.Item label="Auto-Generate ASGN" name="asgn">
                    <Form layout={"inline"}>
                        <Form.Item
                            name="asgnAuto"
                            rules={[{required: false}]}
                            style={{display: 'inline-block', width: 'calc(10%)'}}
                        >
                            <Switch defaultChecked onChange={this.changeHandler} disabled={true}/>
                        </Form.Item>
                        <Form.Item
                            label={"Only Generate ASGN"}
                            name="asgnOnly"
                            rules={[{required: false}]}
                        >
                            <Switch onChange={this.changeHandler}/>
                        </Form.Item>
                    </Form>
                </Form.Item>

                <Form.Item {...this.tailLayout}>
                    <Button type="primary" htmlType="submit" loading={buttonLoading} onClick={this.submitRequest} >
                        Invoke
                    </Button>
                </Form.Item>

            </Form>
        )
    }
}

export default PlaygroundView;