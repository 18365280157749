import React, {useState} from "react";
import {Form, InputNumber} from "antd";
import FormBasicComponent from "./FormBasicComponent";

const FormItemsComponents = ({setResponseJson, setDisableTabs, push, children, marginLeftH, endpoint, payload, httpMethod, initLimit, buttonText}) => {

    const [pagination, setPagination] = useState({limit: initLimit, offset: 0});

    return(
        <FormBasicComponent setResponseJson={setResponseJson} setDisableTabs={setDisableTabs} push={push}
                            endpoint={`${endpoint}?limit=${pagination.limit}&offset=${pagination.offset}`}
                            marginLeftH={marginLeftH} buttonText={buttonText} payload={payload} httpMethod={httpMethod}
                            >

            {children}

            <Form.Item
                label="Limit"
                name="limit"
                rules={[{required: false, message: `Please input the a limit!`}]}
                initialValue={initLimit}
            >
                <InputNumber id={"limit"} min={0} max={500} autoComplete={"off"}
                             onChange={(num) => setPagination({...pagination, limit: num})}/>
            </Form.Item>

            <Form.Item
                label="Offset"
                name="offset"
                rules={[{required: false, message: 'Please enter an offset!'}]}
                initialValue={0}
            >
                <InputNumber id={"offset"} min={0} autoComplete={"off"}
                             onChange={(num) => setPagination({...pagination, offset: num})}/>
            </Form.Item>
        </FormBasicComponent>
    )
}
export default FormItemsComponents;
FormItemsComponents.defaultProps = {
    initLimit: 10,
    buttonText: 'Get Results'
}