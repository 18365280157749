const JavaSnippet = `
package com.mettlapidemo.certificationapibackend.utilities;

import org.apache.tomcat.util.codec.binary.Base64;
import org.springframework.http.HttpMethod;

import javax.crypto.Mac;
import javax.crypto.spec.SecretKeySpec;
import java.io.UnsupportedEncodingException;
import java.net.URLEncoder;
import java.nio.charset.StandardCharsets;
import java.security.InvalidKeyException;
import java.security.NoSuchAlgorithmException;
import java.util.Map;
import java.util.stream.Collectors;

public class SignatureGenerationUtils {

    public static final String HMAC_SHA256 = "HmacSHA256";

    public static String getAsgn(Map<String, String> params, HttpMethod httpMethod, String url, String privateKey) 
    throws NoSuchAlgorithmException, InvalidKeyException, UnsupportedEncodingException {
        return generateAsgn(privateKey, makeConcatenatedString(params, httpMethod, url));
    }

    public static String makeConcatenatedString(Map<String, String> params, HttpMethod httpMethod, String url) {
        String concatenated = params.entrySet()
                .stream()
                .map(s -> s.getValue())
                .collect(Collectors.joining("\\n"));
        return httpMethod.toString() + url + "\\n" + concatenated;
    }

    public static String generateAsgn(final String privateKey, final String concatenatedString) 
    throws NoSuchAlgorithmException, InvalidKeyException, UnsupportedEncodingException {
        if (privateKey == null || concatenatedString == null) throw new NullPointerException();
        final Mac hMacSHA256 = Mac.getInstance(HMAC_SHA256);
        byte[] hmacKeyBytes = privateKey.getBytes(StandardCharsets.UTF_8);
        final SecretKeySpec secretKey = new SecretKeySpec(hmacKeyBytes, HMAC_SHA256);
        hMacSHA256.init(secretKey);
        byte[] dataBytes = concatenatedString.getBytes(StandardCharsets.UTF_8);
        byte[] res = hMacSHA256.doFinal(dataBytes);
        String base64encode = Base64.encodeBase64String(res);
        return URLEncoder.encode(base64encode, "UTF-8");
    }
}
`
export {JavaSnippet}