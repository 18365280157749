import React, {useState} from "react";
import CreateResultView from "../../../utils/CreateResultView";
import TabLayout from "../../../utils/TabLayout";
import FormItemsComponents from "../../../utils/FormItemsComponent";

const ResultsAll = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);

    let interpretation = null;

    if (responseJson.response.hasOwnProperty('applicants') && responseJson.response.applicants.length > 0) {
        interpretation = CreateResultView(responseJson);
    }

    return (
        <TabLayout responseJson={responseJson} disableTabs={disableTabs} interpretation={interpretation}>
            <FormItemsComponents setResponseJson={setResponseJson} setDisableTabs={setDisableTabs} push={push}
                                 endpoint={"results/all"} marginLeftH={"0vw"}/>
        </TabLayout>
    )
}
export default ResultsAll;