import React, {useEffect, useState} from "react";
import AxiosBase from "../../configurations/AxiosBase";
import Notify from "../utils/Notifications";
import {Button, Form, message, Popconfirm, Select} from "antd";

const {Option} = Select;

const SetTimezoneForm = ({tabVisibility, hookData, pushToPath}) => {

    const [buttonLoading, setButtonLoading] = useState(false);
    const [accTimezone, setAccTimezone] = useState({tzid: "Asia/Kolkata"});
    const [tzLoading, setTzLoading] = useState(true);
    const [timezones, setTimezones] = useState((<Option value="Asia/Kolkata">Asia/Kolkata</Option>))

    const layout = {
        labelCol: {span: 10},
        wrapperCol: {span: 4}
    };

    const tailLayout = {
        wrapperCol: {offset: 10, span: 8}
    };

    const makeRequest = async (e) => {
        e.preventDefault();

        setButtonLoading(true);

        try {
            const promise = await AxiosBase().post('timezones', accTimezone);

            if (promise.status === 403) {
                Notify.sessionErrorMessage();
                pushToPath('/')
            } else if (promise.status === 200){
                hookData(promise.data);
                tabVisibility(false);

                Notify.defSuccessMessage();
            } else {
                Notify.defErrorMessage(promise.status)
            }
        } catch (e){
            Notify.internetErrorMessage();
        } finally {
            setButtonLoading(false)
        }
    }

    const getTimezones = async () => {
        try {
            const promise = await AxiosBase().get('timezones');
            if (promise.status === 200) {
                setTimezones(promise.data.response.map(item => {
                    return (
                        <Option key={item} value={item}>{item}</Option>
                    )
                }, setTzLoading(false)));
            } else if (promise.status === 403){
                Notify.sessionErrorMessage();
            }
        } catch (e) {
            Notify.internetErrorMessage();
        }finally {
            setTzLoading(false)
        }
    }

    useEffect(() => {
        getTimezones();
    }, [])

    const confirm = (e) => {
        makeRequest(e);
        message.info({content: 'Requested setting timezone', style: {position: "relative", left: '7vw', top: '3vh'}});
    }

    const cancel = () => {
        message.error('Setting timezone was cancelled!');
    }

    return (
        <>
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: true}}
                onFinish={makeRequest}
                onFinishFailed={() => console.log(`Something went wrong!`)}
            >

                <Form.Item
                    label="Timezone"
                    name="timZone"
                    rules={[{required: true, message: `Please input a timezone!`}]}
                    initialValue={"Asia/Kolkata"}
                >
                    <Select loading={tzLoading} showSearch listHeight={400} onChange={(time) => setAccTimezone({tzid: time})}>
                        {timezones}
                    </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Popconfirm
                        title="Timezone can only be set once. Are you sure you want to proceed?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" htmlType="submit" loading={buttonLoading} style={{minWidth: 90}}>
                            Set Timezone
                        </Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        </>
    )
}
export default SetTimezoneForm;