const local = {
    API_BASE_URL: 'http://localhost:8080',
    PREFIX: `certification.mettl.com/api/v2/`
}

const com = {
    API_BASE_URL: 'https://certification-api-demo.mettl.com',
    PREFIX: `certification.mettl.com/api/v2/`
}

const xyz = {
    API_BASE_URL: 'https://certification-api-demo.mettl.xyz',
    PREFIX: `certification.mettl.xyz/api/v2/`
}

const pro = {
    API_BASE_URL: 'https://certification-api-demo.mettl.pro',
    PREFIX: `certification.mettl.pro/api/v2/`
}

const eu = {
    API_BASE_URL: 'https://certification-api-demo.mercermettl.eu',
    PREFIX: `certification.mercermettl.eu/api/v2/`
}

let env_var;

switch (process.env.REACT_APP_ENV) {
    case 'com':
        env_var = com
        break;
    case 'xyz':
        env_var = xyz
        break;
    case 'pro':
        env_var = pro
        break;
    case 'eu':
        env_var = eu
        break;
    default:
        env_var = local
        break;
}
export {env_var}
