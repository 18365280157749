import React, {Component} from "react";
import Axios from "axios";
import {Input, Modal, notification} from "antd";

import {UserOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {env_var} from "../../environment";


class SessionModal extends Component {

    state = {
        ModalText: 'Content of the modal',
        visible: false,
        confirmLoading: false,
        subdomain: ''
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            visible: nextProps.toggleVisibility
        })
    }

    openNotification = () => {
        notification.open({
            message: 'Session Set Successfully',
            description:
                'Your session for the subdomain ' + this.state.subdomain + ' has been successfully set. Please proceed by clicking on any of the APIs in the menu bar.',
            icon: <CheckOutlined style={{color: '#1dc5b4', marginTop: 25}}/>,
            duration: 3,
            placement: "topRight",
            style: {width: 600}
        });
    };

    openFailedNotification = () => {
        notification.open({
            message: 'Subdomain Not Registered',
            description:
                'Your session for the subdomain ' + this.state.subdomain + ' cannot be set. Please register first and then try again.',
            icon: <CloseOutlined style={{color: '#ff1329', marginTop: 25}}/>,
            duration: 4,
            placement: "topRight",
            style: {width: 600}
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false
        });
        this.props.changeState(false);
    };

    handleInput = (event) => {
        this.setState({
            subdomain: event.target.value
        })
    }

    handleOk = async () => {
        this.setState({
            ModalText: 'Validating Details',
            confirmLoading: true,
        });

        const promise = await Axios.post(env_var.API_BASE_URL + '/api/user/login',
            {subdomain: this.state.subdomain}, {
                validateStatus: () => true
            });

        if (promise.status === 200) {
            this.openNotification();
            this.props.storeJWT(promise.headers.authorization);
            localStorage.setItem('authorizationToken', promise.headers.authorization);
            this.props.setActiveSession(true);
        } else {
            this.openFailedNotification();
        }

        this.setState({
            visible: false,
            confirmLoading: false,
        });
        this.props.changeState(false);
    };

    render() {
        return (
            <Modal
                title="Enter Subdomain"
                visible={this.state.visible}
                onOk={this.handleOk}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.handleCancel}
            >
                <Input size="large" placeholder="Enter Subdomain Here" prefix={<UserOutlined/>}
                       onChange={this.handleInput}/>
            </Modal>
        )
    }
}

const sendData = (dispatch) => {
    return {
        storeJWT: (payload) => {
            dispatch({
                type: 'STORE_AUTHORIZATION', token: payload
            });
        }
    }
}
export default connect(null, sendData)(SessionModal);
SessionModal.defaultProps = {
    setActiveSession: () => console.log(`Active session detected`)
}