import React, {useEffect, useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Form, Input, InputNumber, Select} from "antd";
import AxiosBase from "../../../configurations/AxiosBase";
import Notify from "../../utils/Notifications";

const {Option} = Select;

const BookSlotComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [payload, setPayload] = useState({
        applicantEmail: '', timeZone: 'Asia/Kolkata', slotId: null, certificateAssessmentId: null
    });
    const [timezones, setTimezones] = useState((<Option value="Asia/Kolkata">Asia/Kolkata</Option>))
    const [tests, setTests] = useState();
    const [tzLoading, setTzLoading] = useState(true);
    const [testsLoading, setTestsLoading] = useState(true);

    const getTimezones = async () => {
        const promise = await AxiosBase().get('timezones');
        if (promise.status === 200 && promise.data.response) {
            setTimezones(promise.data.response.map(item => {
                return (
                    <Option key={item} value={item}>{item}</Option>
                )
            }, setTzLoading(false)));
        }
    }

    const getTests = async () => {
        try {
            const promise = await AxiosBase().get('tests/Published');
            if (promise.status === 200 && promise.data.response.hasOwnProperty(`tests`)) {
                setTests(promise.data.response.tests.map(item => {
                    return (
                        <Option key={item.testId} value={item.testId}>{`${item.testId} - ${item.testName}`}</Option>
                    )
                }, setTestsLoading(false)));
            } else if (promise.status === 403) {
                Notify.sessionErrorMessage();
            } else {
                Notify.defErrorMessage(promise.status);
            }
        }catch (e) {
            console.log(`Error received ${e}`);
            Notify.internetErrorMessage();
        }finally {
            setTestsLoading(false)
            setTzLoading(false)
        }
    }

    useEffect(() => {
        getTests();
        getTimezones();
    }, [])

    return(
        <TabLayout responseJson={responseJson} disableTabs={disableTabs}>
            <FormBasicComponent formLayout={"horizontal"} httpMethod={"POST"} buttonText={"Book Slot"}
                                setResponseJson={setResponseJson} setDisableTabs={setDisableTabs} push={push}
                                endpoint={"slot/book"} payload={payload} wrapperColH={4}>
                <Form.Item
                    label="Applicant Email"
                    name="applicantEmail"
                    rules={[{required: true, message: `Please input an email!`}]}
                    initialValue={''}
                >
                    <Input id={"applicantEmail"} allowClear={true} placeholder={"eg. something@something.com"}
                           type={"email"} onChange={(e) => setPayload({...payload, applicantEmail: e.target.value})}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Timezone"
                    name="timZone"
                    rules={[{required: true, message: `Please input a timezone!`}]}
                    initialValue={"Asia/Kolkata"}
                >
                    <Select loading={tzLoading} showSearch listHeight={400}
                            onChange={(timezone) => setPayload({...payload, timeZone: timezone})}>
                        {timezones}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Slot ID"
                    name="slotId"
                    rules={[{required: true, message: 'Please enter a Slot ID!'}]}
                >
                    <InputNumber id={"slotId"} min={0} onChange={(v) => setPayload({...payload, slotId: v})}
                                 autoComplete={"off"} placeholder={"eg. 1910"}/>
                </Form.Item>

                <Form.Item
                    label="Certification Assessment ID"
                    name="certificateAssessmentId"
                    rules={[{required: true, message: 'Please enter a Certificate Assessment ID!'}]}
                >
                    <Select placeholder={"ex. 1199"} loading={testsLoading} showSearch listHeight={250}
                            onChange={(testId) => setPayload({...payload, certificateAssessmentId: parseInt(testId)})}>
                        {tests}
                    </Select>
                </Form.Item>
            </FormBasicComponent>
        </TabLayout>
    )
}
export default BookSlotComponent;