import React, {Component} from "react";
import {Divider, Tabs, Typography} from "antd";
import ReactJson from "react-json-view";
import LoginApplicantView from "./LoginApplicantView";
import {getMoreInformation} from "../../utils/utils";

const {TabPane} = Tabs;
const {Text} = Typography;

class LoginApplicant extends Component {

    state = {
        disableTabs: true,
        response: '',
        request: '',
        URL: ''
    }

    controlTabs = (disabled) => {
        this.setState({
            disableTabs: disabled
        });
    }

    onResponse = (promise) => {
        if (promise.status === 200)
            this.setState({
                ...this.state,
                request: promise.data.request,
                moreInformation: getMoreInformation(promise.data)
            });
        else {
            this.setState({
                response: promise.data
            })
        }
        if (promise.data.response.startsWith('https')){
            this.setState({
                response: promise.data.response,
            })
        } else {
            this.setState({
                response: JSON.parse(promise.data.response),
            })
        }
    }

    render() {

        const {disableTabs, response, request, moreInformation} = this.state;

        return (
            <>
                <Tabs type="card" defaultActiveKey="3">
                    <TabPane tab="Response" key="1" disabled={disableTabs}>
                        {response.hasOwnProperty('error') ?
                            <ReactJson src={response} name={false} iconStyle={"circle"}/> :
                            <Text copyable>{response}</Text>}
                    </TabPane>
                    <TabPane tab="Request" key="2" disabled={disableTabs}>
                        <Divider orientation="left">Request Parameters</Divider>
                        <ReactJson src={request} name={false} iconStyle={"circle"}/>
                        <Divider orientation="left">More Information</Divider>
                        <ReactJson src={moreInformation} name={false} iconStyle={"circle"}/>
                    </TabPane>
                    <TabPane tab="View" key="3">
                        <LoginApplicantView controlTabs={this.controlTabs} updateResponse={this.onResponse} pushToPath={this.props.history.push}/>
                    </TabPane>
                </Tabs>

            </>
        )
    }
}

export default LoginApplicant;