import React, {Component} from "react";
import {Button, Divider, Form, Input} from "antd";
import AxiosBase from "../../../configurations/AxiosBase";
import Notify from "../../utils/Notifications";

class LoginApplicantView extends Component {

    state = {
        disableLogin: true,
        buttonLoading: false,
        emailId: '',
        loginUrl: 'https://google.com'
    }

    layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 8}
    };

    tailLayout = {
        wrapperCol: {offset: 10, span: 8}
    };

    onFinish = values => {
        console.log('Success:', values);
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    inputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
        this.props.controlTabs(true);

    }

    makeRequest = async (e) => {
        e.preventDefault();
        this.setState({
            buttonLoading: true
        });

        try {
            const promise = await AxiosBase().post('/login', {
                email: this.state.email
            });

            if (promise.status === 200) {
                this.setState({
                    loginUrl: promise.data.response,
                    buttonLoading: false,
                    disableLogin: false
                });
                this.props.controlTabs(false);
                this.props.updateResponse(promise);
                Notify.defSuccessMessage();

                if (!promise.data.response.startsWith('https')) {
                    this.setState({
                        disableLogin: true
                    });
                }
                setTimeout(() => {
                    this.setState({
                        disableLogin: true
                    })
                }, 60000);
            } else {
                if (promise.status === 403){
                    Notify.sessionErrorMessage();
                    this.props.pushToPath('/')
                    return;
                }
                Notify.defErrorMessage(promise.status);
            }
        } catch (e) {
            console.log(`Error ${e}`);
            Notify.internetErrorMessage();
        }
    }

    disableButton = () => {
        this.setState({
            disableLogin: true
        });
    }

    render() {

        const {disableLogin, buttonLoading, loginUrl, emailId} = this.state;

        return (
            <Form
                {...this.layout}
                name="basic"
                initialValues={{emailId: ''}}
                onFinish={this.makeRequest}
                onFinishFailed={this.onFinishFailed}
                style={{marginTop:70}}
            >
                <Form.Item
                    label="Email ID"
                    name="email"
                    initialValue={emailId}
                    rules={[{required: true, message: 'Please input your emailId!'}]}
                >
                    <Input id={"email"} allowClear={true} placeholder={"eg. something@something.com"}
                           type={"email"} onChange={this.inputChange} autoComplete={"off"} />
                </Form.Item>

                <Form.Item {...this.tailLayout}>
                    <Button type="primary" htmlType="submit" loading={buttonLoading} onClick={this.makeRequest}>
                        Generate Login
                    </Button>
                </Form.Item>

                <Divider orientation="center" />

                <Form.Item {...this.tailLayout}>
                    <Button type="default" onClick={this.disableButton} htmlType="submit" loading={false} disabled={disableLogin} href={loginUrl} target={"_blank"}>
                        Click To Login
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default LoginApplicantView;