import {Table} from "antd";
import React from "react";

const CreateResultView = (responseJson) => {
        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Candidate Name',
                dataIndex: 'firstName',
                key: 'firstName'
            },
            {
                title: 'Test Name',
                dataIndex: 'certificationName',
                // dataIndex: ['certificates',[0],'certificationName'],
                key: 'testName',
            },
            {
                title: 'Percentage',
                dataIndex: 'scorePercentage',
                // dataIndex: ['certificates',[0],'certificationName'],
                key: 'scorePercentage',
                width: 150
            },
        ];

        let tableData = [];
        if (responseJson.response.hasOwnProperty('applicants') && responseJson.response.applicants.length > 0) {
            // eslint-disable-next-line array-callback-return
            responseJson.response.applicants.map(itemA => {
                // eslint-disable-next-line array-callback-return
                itemA.certificates.map(itemC => {
                    tableData = [...tableData, {...itemC, email: itemA.email, firstName: itemA.firstName}]
                })
            })
        }
        return (<Table dataSource={tableData} columns={columns} size={"middle"} bordered scroll={{y: "47vh"}}/>);
}
export default CreateResultView;