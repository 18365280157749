import React from "react";
import {message, notification} from "antd";

import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

const style = {style: {position: "relative", left: '7vw', top: '3vh'}}

class Notify {
    static responseReceivedNotification = () => {
        notification.open({
            message: 'Response Received',
            description:
                'Successfully received response from the server. Please check the request and response tabs to view data.',
            icon: <CheckOutlined style={{color: '#1dc5b4', marginTop: 25}}/>,
            duration: 4,
            placement: "topRight",
            style: {width: 600}
        });
    }

    static sessionNotFoundNotification = () => {
        notification.open({
            message: 'No session found',
            description:
                `You don't currently have a session set. Please proceed by clicking on Create Session button.`,
            icon: <CloseOutlined style={{color: '#ff1329', marginTop: 25}}/>,
            duration: 4,
            placement: "topRight",
            style: {width: 600}
        });
    };

    static successMessage = (content) => {
        message.success({content: `${content}`, ...style });
    }

    static errorMessage = (content) => {
        message.error({content: `${content}`, ...style });
    }

    static defSuccessMessage = () => {
        message.success({content: `Response Received`, ...style });
    }

    static defErrorMessage = (statusCode) => {
        message.error({content: `Something Went Wrong. Server Returned ${statusCode}`, ...style });
    }

    static internetErrorMessage = () => {
        message.error({content: `Please check your internet connection`, ...style });
    }

    static sessionErrorMessage = () => {
        message.error({content: `Session Not Set`, ...style });
    }
}
export default Notify;