import React, {useEffect, useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Form, Input, Switch} from "antd";

const MapApplicantComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [textForButton, setTextForButton] = useState("Add")
    const [add, setAdd] = useState([{testName: null, testId: null}]);
    const [remove, setRemove] = useState(null);
    const [email, setEmail] = useState(null);
    const [payload, setPayload] = useState({
            applicants: [{
                tests: {
                    add: [{testName: null, testId: null}],
                    remove: null
                },
                email: null
            }]
        }
    );

    const toggleSwitch = (checked) => {
        if (checked === false) {
            setTextForButton("Remove");
            setRemove(add);
            setAdd(null)

        } else {
            setTextForButton("Add");
            setAdd(remove);
            setRemove(null)
        }
    }

    const handleChange = e => {

        if (e.target.id === 'emailId') {
            setEmail(e.target.value);
        } else {
            if (textForButton === 'Add') {
                setAdd([{...add[0], [e.target.id]: e.target.value}]);
            } else {
                setRemove([{...remove[0], [e.target.id]: e.target.value}]);
            }
        }
    }

    useEffect(() => {
        setPayload({
            applicants: [{
                email: email,
                tests: {
                    add: add,
                    remove: remove
                }
            }]
        })
    }, [textForButton, add, remove, email]);


    return (
        <TabLayout disableTabs={disableTabs} responseJson={responseJson}>
            <FormBasicComponent push={push} httpMethod={"POST"} endpoint={"map"} payload={payload}
                                setDisableTabs={setDisableTabs} setResponseJson={setResponseJson}
                                formLayout={"horizontal"}
                                buttonText={textForButton} labelColH={9} wrapperColH={6}>
                <Form.Item
                    label="Email ID"
                    name="emailId"
                    rules={[{required: true, message: 'Please input your Email ID!'}]}
                >
                    <Input id={"emailId"} allowClear={true} placeholder={"eg. something@something.com"}
                           type={"email"} onChange={handleChange} autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Test Name"
                    name="testName"
                    rules={[{required: true, message: `Please input the Test's Name!`}]}
                >
                    <Input id={"testName"} allowClear={true} placeholder={"eg. Recruit Assessment"}
                           onChange={handleChange}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Test ID"
                    name="testId"
                    rules={[{required: true, message: 'Please enter the Test ID!'}]}
                >
                    <Input id={"testId"} allowClear={true} placeholder={"eg. 1111"} onChange={handleChange}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Add/Remove"
                    name="map"
                >
                    <Switch defaultChecked onChange={toggleSwitch}/>
                </Form.Item>
            </FormBasicComponent>
        </TabLayout>
    )
}
export default MapApplicantComponent;