import React, {useState} from "react";
import FormItemsComponents from "../../utils/FormItemsComponent";
import TabLayout from "../../utils/TabLayout";
import {Table} from "antd";

const SlotWindowsComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);


    const createTable = () => {

        const columns = [
            {
                title: 'Slot ID',
                dataIndex: 'slotId',
                // dataIndex: ['certificates',[0],'certificationName'],
                key: 'testName',
                width: 120,
                align: "center"
            },
            {
                title: 'Slot Window ID',
                dataIndex: 'slotWindowId',
                key: 'name',
                width: 120,
                align: "center"
            },
            {
                title: 'Window Name',
                dataIndex: 'name',
                key: 'name',
                align: "center"
            },
            {
                title: 'Capacity',
                dataIndex: 'cap',
                key: 'capacity',
                align: "center",
                width: 100
            },
            {
                title: 'Enrolled',
                dataIndex: 'enrolled',
                key: 'enrolled',
                align: "center",
                width: 100
            },
            {
                title: 'From',
                dataIndex: 'fromTime',
                // dataIndex: ['certificates',[0],'certificationName'],
                key: 'fromTime',
                align: "center"
            },
            {
                title: 'To',
                dataIndex: 'toTime',
                // dataIndex: ['certificates',[0],'certificationName'],
                key: 'toTime',
                align: "center"
            },
        ];

        let tableData = [];

        // eslint-disable-next-line array-callback-return
        responseJson.response.slotWindows.map(itemSW => {
            // eslint-disable-next-line array-callback-return
            itemSW.slots.map(itemS => {
                tableData = [...tableData, {slotWindowId: itemSW.slotWindowId,
                    enrolled: itemS.enrolledCount === null ? 0 : itemS.enrolledCount ,name: itemSW.name,
                    cap: itemS.capacity === null ? 'Unlimited' : itemS.capacity, ...itemS}]
            });
        });

        return (<Table dataSource={tableData} columns={columns} size={"middle"} bordered scroll={{y: "47vh"}}/>);
    }

    let createView = null;

    if (responseJson.response.hasOwnProperty('slotWindows') && responseJson.response.slotWindows.length > 0) {
        createView = createTable();
    }

    return(
        <TabLayout disableTabs={disableTabs} responseJson={responseJson} interpretation={createView}>
            <FormItemsComponents push={push} setDisableTabs={setDisableTabs} setResponseJson={setResponseJson}
                                 buttonText={"Get Windows"} endpoint={`slot/windows`} marginLeftH={"0vw"} >
            </FormItemsComponents>
        </TabLayout>
    )
}
export default SlotWindowsComponent;