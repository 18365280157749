import React, {useState} from "react";
import CreateResultView from "../../../utils/CreateResultView";
import TabLayout from "../../../utils/TabLayout";
import FormItemsComponents from "../../../utils/FormItemsComponent";
import {Form, InputNumber} from "antd";

const ResultsAssessment = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [assessmentId, setAssessmentId] = useState('');

    let interpretation = null;

    if (responseJson.response.hasOwnProperty('applicants') && responseJson.response.applicants.length > 0) {
        interpretation = CreateResultView(responseJson);
    }

    return (
        <TabLayout responseJson={responseJson} disableTabs={disableTabs} interpretation={interpretation}>
            <FormItemsComponents setResponseJson={setResponseJson} setDisableTabs={setDisableTabs} push={push}
                                 marginLeftH={"0vw"} endpoint={`results/assessment/${assessmentId}`} >
                <Form.Item
                    label="Assessment ID"
                    name="assessmentId"
                    rules={[{required: true, message: `Mandatory!`}]}
                    initialValue={''}
                >
                    <InputNumber id={"assessmentId"} min={0} onChange={(v) => setAssessmentId(v)} autoComplete={"off"} placeholder={"ex. 1910"}/>
                </Form.Item>
            </FormItemsComponents>
        </TabLayout>
    )
}
export default ResultsAssessment;