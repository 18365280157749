import React from "react";
import {Divider, Tabs} from "antd";
import ReactJson from "react-json-view";
import {getMoreInformation} from "./utils";

const {TabPane} = Tabs;

const TabLayout = ({responseTab, reactJson, interpretation, responseJson, disableTabs, children, groupArraysAfterLength, showDivider}) => {

    const {response, request} = responseJson;

    return (
        <>
            <Tabs type="card" defaultActiveKey="3">
                <TabPane tab="Response" key="1" disabled={disableTabs}>
                    {responseTab ?
                        <ReactJson src={response} groupArraysAfterLength={groupArraysAfterLength} name={false} iconStyle={"circle"}/> :
                        reactJson}
                </TabPane>
                <TabPane tab="Request" key="2" disabled={disableTabs}>
                    <Divider orientation="left">Request Parameters</Divider>
                    <ReactJson src={request} name={false} iconStyle={"circle"}/>
                    <Divider orientation="left">More Information</Divider>
                    <ReactJson src={getMoreInformation(responseJson)} name={false} iconStyle={"circle"}/>
                </TabPane>
                <TabPane tab="View" key="3">
                    {children}
                    {showDivider && <Divider/>}
                    {interpretation}
                </TabPane>
            </Tabs>
        </>
    )
}
export default TabLayout;
TabLayout.defaultProps = {
    responseTab: true,
    interpretation: null,
    groupArraysAfterLength: 50,
    showDivider: true
}