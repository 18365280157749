import React, {useState} from "react";
import {Divider, Tabs} from "antd";
import ReactJson from "react-json-view";
import SetTimezoneForm from "./SetTimezoneForm";

const {TabPane} = Tabs;

const SetTimezone = ({history: {push}}) => {

    const [response, setResponse] = useState({});
    const [request, setRequest] = useState(null);
    const [URL, setURL] = useState({});
    const [disableTabs, setDisableTabs] = useState(true);

    const controlTabs = (tabsVisibility) => {
        setDisableTabs(tabsVisibility);
    }

    const updateHooks = (dateFromPromise) => {
        setResponse(dateFromPromise.response);
        setRequest(dateFromPromise.request);
        setURL({
            "Request Method": dateFromPromise.httpMethod,
            "Concatenated String": dateFromPromise.concatenatedString,
            Signature: dateFromPromise.signature,
            URL: dateFromPromise.url});
    }

    return (
        <>
            <Tabs type="card" defaultActiveKey="3">
                <TabPane tab="Response" key="1" disabled={disableTabs}>
                    <ReactJson src={response} groupArraysAfterLength={false} name={false} iconStyle={"circle"}/>
                </TabPane>
                <TabPane tab="Request" key="2" disabled={disableTabs}>
                    <Divider orientation="left">Request Parameters</Divider>
                    <ReactJson src={request} name={false} iconStyle={"circle"}/>
                    <Divider orientation="left">More Information</Divider>
                    <ReactJson src={URL} name={false} iconStyle={"circle"}/>
                </TabPane>
                <TabPane tab="View" key="3">
                    <SetTimezoneForm pushToPath={push} tabVisibility={controlTabs} hookData={updateHooks}/>
                    <Divider/>
                </TabPane>
            </Tabs>
        </>
    )
}
export default SetTimezone;