import React, {useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormItemsComponents from "../../utils/FormItemsComponent";
import {Descriptions, Form, InputNumber} from "antd";

const ApplicantsInSlotComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [slotWindowId, setSlotWindowId] = useState(null);

    let createView = null;

    if (responseJson.response.hasOwnProperty('slotWindowSummary') && (responseJson.response.slotWindowSummary.length > 0)) {
        createView = (
            responseJson.response.slotWindowSummary.map(obj => {
                return (
                    <div key={obj.email} style={{padding:30 ,background: "#ececec", marginBottom: 20}}>
                        <Descriptions title={obj.candidateName}>
                            <Descriptions.Item label="Email">{obj.email}</Descriptions.Item>
                            <Descriptions.Item label="Slot ID">{obj.slotId}</Descriptions.Item>
                            <Descriptions.Item label="Timezone">{obj.bookingTimezone}</Descriptions.Item>
                            <Descriptions.Item label="Test Name">{obj.assessmentName}</Descriptions.Item>
                            <Descriptions.Item label="Test ID">{obj.certificationAssessmentId}</Descriptions.Item>
                            <Descriptions.Item label="Booking Time">{obj.bookingTime}</Descriptions.Item>
                            <Descriptions.Item label="Slot From">{obj.slotFromTime}</Descriptions.Item>
                            <Descriptions.Item label="Slot Till">{obj.slotToTime}</Descriptions.Item>
                        </Descriptions>
                    </div>
                )
            })
        )
    }

    return(
        <TabLayout responseJson={responseJson} disableTabs={disableTabs} interpretation={createView}>
            <FormItemsComponents buttonText={"Get Applicants"} setResponseJson={setResponseJson} setDisableTabs={setDisableTabs}
                                 endpoint={`slot/window/${slotWindowId}`} push={push} marginLeftH={"0vw"}>
                <Form.Item
                    label="Slot Window ID"
                    name="slotWindowId"
                    rules={[{required: true, message: 'Please input a valid Slot Window ID!'}]}
                >
                    <InputNumber id={"slotWindowId"} min={0} placeholder={"1910"}
                                 onChange={(v) => setSlotWindowId(v)} autoComplete={"off"}/>
                </Form.Item>
            </FormItemsComponents>
        </TabLayout>
    )
}
export default ApplicantsInSlotComponent;