import React, {Component} from "react";
import {Card, Row, Col, Divider, Switch, Tabs, Spin, Empty} from "antd";
import AxiosBase from "../../configurations/AxiosBase";
import './GetAllTestsStyles.css';
import ReactJson from "react-json-view";
import Notify from "../utils/Notifications";
import {getMoreInformation} from "../utils/utils";

const {TabPane} = Tabs;

class GetAllTests extends Component {

    state = {
        correctResponse: false,
        statusCode: '',
        visible: false,
        showLoader: true,
        status: 'Published',
        tests: {
            published: [],
            unpublished: []
        },
        disableTabs: true
    }

    async componentDidMount() {
        try {
            const promise = await AxiosBase().get('tests/Published');

            if (promise.status === 200) {
                if (promise.data.response.hasOwnProperty(`tests`)) {
                    this.setState({
                        response: promise.data.response,
                        request: promise.data.request,
                        tests: {
                            ...this.state.tests,
                            published: promise.data.response.tests
                        },
                        moreInformation: getMoreInformation(promise.data),
                        correctResponse: true
                    });
                } else {
                    this.setState({
                        response: promise.data.response,
                        request: promise.data.request,
                        moreInformation: getMoreInformation(promise.data),
                        correctResponse: false
                    });
                }
            } else if (promise.status === 403) {
                Notify.sessionErrorMessage();
                this.props.history.push('/');
                this.setState({
                    statusCode: promise.status
                })
            } else {
                Notify.defErrorMessage(promise.status);
            }
            const unpublished = await AxiosBase().get('tests/UnPublished');
            if (promise.status === 200) {
                this.setState({
                    tests: {
                        ...this.state.tests,
                        unpublished: unpublished.data.response.tests
                    },
                    showLoader: false,
                    disableTabs: false
                });
                Notify.defSuccessMessage();
            }
        } catch (e) {
            Notify.internetErrorMessage();
        }
    }

    onChange = (checked) => {
        if (checked === false) {
            this.setState({
                status: 'UnPublished'
            })
        } else if (checked === true) {
            this.setState({
                status: 'Published'
            })
        }
    }

    render() {
        const {
            status,
            showLoader,
            tests: {published: p, unpublished: u},
            response,
            request,
            moreInformation,
            disableTabs,
            correctResponse
        } = this.state;
        let published, unpublished;

        if (correctResponse === true) {
            if (p.length > 0) {
                published = (
                    p.map(test => {
                        return (
                            <Col span={4} key={test.testId}>
                                <Card title={test.testId} bordered={true}
                                      style={{marginBottom: 14, minHeight: 200, overflowWrap: "break-word"}}>
                                    <h4>Test Name</h4>
                                    {test.testName}
                                </Card>
                            </Col>)
                    })
                );
            }
            if (u.length > 0) {
                unpublished = (
                    u.map(test => {
                        return (
                            <Col span={4} key={test.testId}>
                                <Card title={test.testId} bordered={true}
                                      style={{marginBottom: 14, minHeight: 200, overflowWrap: "break-word"}}>
                                    <h4>Test Name</h4>
                                    {test.testName}
                                </Card>
                            </Col>)
                    })
                );
            }
        }

        return (
            <>
                <Tabs type="card" defaultActiveKey="3">
                    <TabPane tab="Response" key="1" disabled={disableTabs}>
                        {!showLoader ? <><ReactJson src={response} name={false} iconStyle={"circle"}/></> :
                            <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                    </TabPane>
                    <TabPane tab="Request" key="2" disabled={disableTabs}>
                        <Divider orientation="left">Request Parameters</Divider>
                        <ReactJson src={request} name={false} iconStyle={"circle"}/>
                        <Divider orientation="left">More Information</Divider>
                        <ReactJson src={moreInformation} name={false} iconStyle={"circle"}/>
                    </TabPane>
                    <TabPane tab="View" key="3">
                        <Switch size="small" defaultChecked onChange={this.onChange}/>
                        <Divider orientation="left">{status} Tests</Divider>
                        <div className="site-card-wrapper">
                            {showLoader ? <Spin size="large" style={{
                                marginLeft: "50%",
                                marginTop: "15%"
                            }}/> : correctResponse ? status === 'Published' ? <Row gutter={16}>{published}</Row> :
                                <Row gutter={16}>{unpublished}</Row> : <Empty/>}
                        </div>
                    </TabPane>
                </Tabs>
            </>
        )
    }
}

export default GetAllTests;