import React, {Component} from "react";
import AxiosBase from "../../configurations/AxiosBase";
import {Button, Select, Form} from "antd";
import Notify from "../utils/Notifications";

const {Option} = Select;

class UpdateSettingsForm extends Component {

    state = {
        buttonLoading: false,
        accountSettings: {
            applicantDashboardViewSettings: {
                sortByName: false,
                sortByPublishDate: true
            },
            applicantReportSettings: {
                showReportToApplicant: false,
                showResultOnTestCompletion: true
            },
            emailSettings: {
                sendSlotSelectionEmail: true,
                sendSlotReminderEmail: true,
                sendEmailOnAssigningTest: false
            },
            weekendSlotSetting: {
                disableWeekendSlotting: false
            },
            dataSecuritySettings: {
                enableMettlSupportTeamAccess: true
            }
        }
    }

    layout = {
        labelCol: {span: 9},
        wrapperCol: {span: 6}
    };

    tailLayout = {
        wrapperCol: {offset: 9, span: 8}
    };


    makeRequest = async (e) => {
        e.preventDefault();
        const {accountSettings} = this.state;
        const {hookData, tabVisibility} = this.props;
        this.setState({
            buttonLoading: true
        });

        let promise = null;
        try {
            promise = await AxiosBase().post('account/settings', accountSettings);
            if (promise.status !== 200) {
                if (promise.status === 403){
                    Notify.sessionErrorMessage()
                    this.props.pushToPath('/')
                }else {
                    Notify.defErrorMessage(promise.status)
                    this.setState({
                        buttonLoading: false
                    })
                }
            } else {
                hookData(promise.data);
                tabVisibility(false);

                this.setState({
                    buttonLoading: false
                })
                Notify.defSuccessMessage()
            }
        }
        catch (e){
            Notify.internetErrorMessage()
            this.setState({
                buttonLoading: false
            })
        }
    }

    handleSelect = (option) => {
        let modifyingObject = {};
        switch (option) {
            case `sortByName`:
                modifyingObject = {
                    applicantDashboardViewSettings: {
                        [option]: true,
                        sortByPublishDate: false
                    }
                }
                break;
            case `sortByPublishDate`:
                modifyingObject = {
                    applicantDashboardViewSettings: {
                        [option]: true,
                        sortByName: false
                    }
                }
                break;
            case `enableWeekendSlotting`:
                modifyingObject = {
                    weekendSlotSetting: {
                        disableWeekendSlotting: false
                    }
                }
                break;
            case `disableWeekendSlotting`:
                modifyingObject = {
                    weekendSlotSetting: {
                        [option]: true
                    }
                }
                break;
            case `enableMettlSupportTeamAccess`:
                modifyingObject = {
                    dataSecuritySettings: {
                        [option]: true
                    }
                }
                break;
            case `disableMettlSupportTeamAccess`:
                modifyingObject = {
                    dataSecuritySettings: {
                        enableMettlSupportTeamAccess: false
                    }
                }
                break;
            default:
                console.log(`Incorrect input ${option} received`)
                break;
        }
        this.setState({
            accountSettings: {
                ...this.state.accountSettings,
                ...modifyingObject
            }
        })
    }

    setAllFalse = (item, nameOfObject) => {
        return new Promise(resolve => {
            this.setState({
                accountSettings: {
                    ...this.state.accountSettings,
                    [nameOfObject]: {
                        ...this.state.accountSettings[nameOfObject],
                        [item]: false
                    }
                }
            }, () => resolve())
        });
    }

    handleReportSettings = (selected) => {
        let objectName = `applicantReportSettings`;
        this.handleHelper(objectName, selected);
    }

    handleEmailSettings = (selected) => {
        let objectName = `emailSettings`;
        this.handleHelper(objectName, selected);
    }

    handleHelper = async (objectName, selected) => {
        for (const item in this.state.accountSettings[objectName]) {
            await this.setAllFalse(item, objectName)
        }
        let objectModifier = {}
        // eslint-disable-next-line array-callback-return
        selected.map(item => {
            objectModifier[item] = true
        })
        this.setState({
            accountSettings: {
                ...this.state.accountSettings,
                [objectName]: {
                    ...this.state.accountSettings[objectName],
                    ...objectModifier
                }
            }
        });
    }

    render() {

        const {buttonLoading} = this.state;

        return (
            <>
                <Form
                    {...this.layout}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    style={{marginTop: 50}}
                >
                    <Form.Item
                        label="Sort Dashboard"
                        name="applicantDashboardViewSettings"
                    >
                        <Select defaultValue={"sortByPublishDate"} onChange={this.handleSelect}>
                            <Option value="sortByName">By Name</Option>
                            <Option value="sortByPublishDate">By Published Date</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Report Settings"
                        name="applicantReportSettings"
                    >
                        <Select mode="tags" placeholder="Report Settings" defaultValue={'showResultOnTestCompletion'}
                                onChange={this.handleReportSettings}>
                            <Option value="showReportToApplicant">Show Report To Applicant</Option>
                            <Option value="showResultOnTestCompletion">Show Result On Test Finish</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Email Settings"
                        name="emailSettings"
                    >
                        <Select mode="tags" placeholder="Email Settings"
                                defaultValue={['sendSlotSelectionEmail', 'sendSlotReminderEmail']}
                                onChange={this.handleEmailSettings}>
                            <Option value="sendSlotSelectionEmail">Send Slot Selection Email</Option>
                            <Option value="sendSlotReminderEmail">Send Slot Reminder Email</Option>
                            <Option value="sendEmailOnAssigningTest">Send Email On Assigning Test</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Weekend Slotting"
                        name="weekendSlotSetting"
                    >
                        <Select defaultValue={"enableWeekendSlotting"} onChange={this.handleSelect}>
                            <Option value="enableWeekendSlotting">Enable</Option>
                            <Option value="disableWeekendSlotting">Disable</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Support Team Access"
                        name="dataSecuritySettings"
                    >
                        <Select defaultValue={"enableMettlSupportTeamAccess"} onChange={this.handleSelect}>
                            <Option value="enableMettlSupportTeamAccess">Enable</Option>
                            <Option value="disableMettlSupportTeamAccess">Disable</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item {...this.tailLayout}>
                        <Button type="primary" htmlType="submit" loading={buttonLoading} onClick={this.makeRequest}
                                style={{minWidth: 90}}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default UpdateSettingsForm;