const GoSnippet = `
package request

import (
   "crypto/hmac"
   "crypto/sha256"
   "encoding/base64"
   "net/url"
   "sort"
)

func GenerateConcatenatedString(method string, params map[string]string, url string) string {
   resultString := ""

   keys := make([]string, 0)
   for k := range params{
      keys = append(keys, k)
   }
   sort.Strings(keys)

   for _, v := range keys {
      resultString = resultString + "\\n" + params[v]
   }
   return method + url + resultString
}

func GenerateEncodedHMACSHA256(privateKey string, concatenateString string) string {
   secret := privateKey
   data := concatenateString

   h := hmac.New(sha256.New, []byte(secret))

   h.Write([]byte(data))

   encodedInB64 := base64.StdEncoding.EncodeToString(h.Sum(nil))

   asgn := url.QueryEscape(encodedInB64)
   return asgn
}

func GenerateAsgn(method string, params map[string]string, url string, privateKey string) string {
   concat := GenerateConcatenatedString(method, params, url)
   asgn := GenerateEncodedHMACSHA256(privateKey, concat)
   return asgn
}
`
export {GoSnippet}
