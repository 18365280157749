import React, {Component} from "react";
import AxiosBase from "../../configurations/AxiosBase";
import {Card, Tabs, Spin, Divider, Empty} from "antd";
import ReactJson from "react-json-view";
import Notify from "../utils/Notifications";

const {TabPane} = Tabs;

class GetAllTimezones extends Component {

    state = {
        showJson: false,
        timezones: [],
        gridStyle: {
            width: '20%',
            textAlign: 'center',
            overflow: "clip"
        },
        request: {},
        moreInformation: {},
        disableTabs: true,
        error: false,
        correctResponse: false
    }

    async componentDidMount() {
        try {
            const response = await AxiosBase().get('timezones');

            if (response.status === 200) {
                if (response.data.response.status !== `ERROR`){
                    this.setState({
                        timezones: response.data.response,
                        correctResponse: true,
                        showJson: true,
                        request: response.data.request,
                        disableTabs: false,
                        moreInformation: {
                            "Request Method": response.data.httpMethod,
                            "Concatenated String": response.data.concatenatedString,
                            Signature: response.data.signature,
                            URL: response.data.url
                        }
                    },);
                    Notify.defSuccessMessage();
                } else {
                    this.setState({
                        showJson: true,
                        request: response.data.request,
                        disableTabs: false,
                        correctResponse: false,
                        timezones: response.data.response,
                        moreInformation: {
                            "Request Method": response.data.httpMethod,
                            "Concatenated String": response.data.concatenatedString,
                            Signature: response.data.signature,
                            URL: response.data.url
                        }
                    },);
                    Notify.defSuccessMessage();
                }

            } else if (response.status === 403 ) {
                this.props.history.push('/')
                this.setState({
                    error: true,
                    showJson: true
                })
                Notify.sessionErrorMessage();
            }else {
                Notify.defErrorMessage(response.status);
                this.setState({
                    error: true,
                    showJson: true
                })
            }
        } catch (e) {
            Notify.internetErrorMessage()
        }
    }

    render() {

        const {timezones, gridStyle, showJson, request, moreInformation, disableTabs, error, correctResponse} = this.state;

        const timezonesRender = timezones.length && !error && correctResponse ? <Card title="Timezones">
                {timezones.map(o => {
                    return (
                        <Card.Grid style={gridStyle} key={o}>{o}</Card.Grid>
                    )
                })} </Card> :
            !showJson ? <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/> : <Empty/>

        return (
            <>
                <Tabs type="card" defaultActiveKey={"3"}>
                    <TabPane tab="Response" key="1" disabled={disableTabs}>
                        {showJson ? <><ReactJson src={timezones} collapsed={50} name={false}
                                                 iconStyle={"circle"}/></> :
                            <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                    </TabPane>
                    <TabPane tab="Request" key="2" disabled={disableTabs}>
                        {showJson ? <>
                            <Divider orientation="left">Request Parameters</Divider>
                            <ReactJson src={request} name={false} iconStyle={"circle"}/>
                            <Divider orientation="left">More Information</Divider>
                            <ReactJson displayDataTypes={false} src={moreInformation} name={false} iconStyle={"circle"}/>
                        </> : <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                    </TabPane>
                    <TabPane tab="View" key="3">
                        {timezonesRender}
                    </TabPane>
                </Tabs>
            </>
        )
    }
}

export default GetAllTimezones;