import React, {Component} from "react";
import {Drawer, Form, Button, Col, Row, Input, notification} from 'antd';
import {FormOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import Axios from "axios";
import {env_var} from "../../environment";
import {connect} from "react-redux";

class RegisterModal extends Component {

    state = {
        drawerVisible: false,
        loading: false,
        payload: {
            subdomain: '',
            private_key: '',
            public_key: ''
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            drawerVisible: nextProps.drawerVisible
        })
    }

    onClose = () => {
        this.setState({
            drawerVisible: false,
        });
        this.props.updateParent(false);
    };

    changeHandler = (event) => {
        this.setState({
            payload: {
                ...this.state.payload,
                [event.target.id]: event.target.value
            }
        });
    }

    submitHandler = async (event) => {
        event.preventDefault();
        const promise = await Axios.post(env_var.API_BASE_URL + '/api/user/login',
            this.state.payload, {
            validateStatus: () => true
            });
        if (promise.status === 200) {
            this.openNotification();
            this.props.storeJWT(promise.headers.authorization);
            localStorage.setItem('authorizationToken', promise.headers.authorization);
            this.props.setActiveSession(true);
        } else {
            this.openFailedNotification();
        }
        this.onClose();
    }

    openFailedNotification = () => {
        notification.open({
            message: 'Unable to create session',
            description:
                'We were unable to set your API keys. Please connect with Mercer Mettl Technical Support for assistance.',
            icon: <CloseOutlined style={{color: '#ff1329', marginTop: 25}}/>,
            duration: 4,
            placement: "topRight",
            style: {width: 600}
        });
    };

    openNotification = () => {
        notification.open({
            message: 'Key Set Successfully',
            description:
                'Your credentials for the subdomain ' + this.state.payload.subdomain + ' have been successfully set. Please try out the APIs now.',
            icon: <CheckOutlined style={{color: '#1dc5b4', marginTop: 25}}/>,
            duration: 10,
            placement: "topRight",
            style: {width: 600}
        });
    };

    render() {
        const {drawerVisible, loading} = this.state;
        return (
            <>
                <Drawer
                    placement="right"
                    title="Set API keys"
                    width={420}
                    onClose={this.onClose}
                    visible={drawerVisible}
                    bodyStyle={{paddingBottom: 10}}
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.onClose} style={{marginRight: 8}}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                icon={<FormOutlined/>}
                                loading={loading}
                                onClick={this.submitHandler}
                            >
                                Register
                            </Button>
                        </div>
                    }
                >
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="subdomain"
                                    label="Subdomain"
                                    rules={[{required: true, message: 'Subdomain cannot be blank'}]}
                                >
                                    <Input placeholder="Please enter subdomain" style={{width: 360}}
                                           onChange={this.changeHandler} id="subdomain"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="public_key"
                                    label="Public Key"
                                    rules={[{required: true, message: 'Please enter a public key'}]}
                                >
                                    <Input placeholder="Please enter public key" style={{width: 360}}
                                           onChange={this.changeHandler} id="public_key"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="private_key"
                                    label="Private Key"
                                    rules={[{required: true, message: 'Please enter a private key'}]}
                                >
                                    <Input placeholder="Please enter private key" style={{width: 360}}
                                           onChange={this.changeHandler} id="private_key"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </>
        );
    }
}
const sendData = (dispatch) => {
    return {
        storeJWT: (payload) => {
            dispatch({
                type: 'STORE_AUTHORIZATION', token: payload
            });
        }
    }
}
export default connect(null, sendData)(RegisterModal);
RegisterModal.defaultProps = {
    setActiveSession: () => null
}