import React, {Component} from "react";
import {Button, Divider, Result, Spin} from "antd";
import {LoginOutlined, PlusOutlined} from '@ant-design/icons';
import SessionModal from "./layout/SessionModal";
import RegisterModal from "./layout/RegisterModal";
import AxiosBase from "../configurations/AxiosBase";
import Notify from "./utils/Notifications";

class LoginAndSignUp extends Component {

    state = {
        visible: false,
        drawerVisible: false,
        activeSession: false,
        spinner: true
    }

    setActiveSession = (boolean) => {
        this.setState({
            activeSession: boolean
        })
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };
    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    toggleModal = (val) => {
        this.setState({
            visible: val
        })
    }
    updateDrawerVisibility = (val) => {
        this.setState({
            drawerVisible: val
        })
    }

    noSession = (<Result
        style={{height: "500px"}}
        status="500"
        title="API Keys Not Set"
        subTitle="Please set your API keys to begin invoking."
        extra={[<Button type="primary" icon={<LoginOutlined/>} size="large" onClick={this.showDrawer}
                        style={{marginBottom: 10}} key={"1"}>
            Click To Set Credentials
        </Button>]}
    />)

    activeSession = (<Result
        style={{height: "calc(100vh - 350px)", marginTop: 70}}
        status="success"
        title="You have an active session!"
        subTitle="Start invoking the APIs by selecting the items at the left."
        extra={[
            <Button type="primary" key="changeSubdomain" onClick={this.showDrawer}>
                Change Credentials
            </Button>,
        ]}
    />)

    async componentDidMount() {
        try {
            const res = await AxiosBase().get("active");
            if (res.status === 200)
                this.setState({
                    activeSession: true
                });
        } catch (e) {
            Notify.internetErrorMessage();
        } finally {
            this.setState({
                spinner: false
            })
        }
    }

    render() {
        const {activeSession, spinner} = this.state;
        const result = activeSession ? this.activeSession : this.noSession;

        return (
            <>
                {spinner ? <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/> : result}
                <SessionModal toggleVisibility={this.state.visible} changeState={this.toggleModal}
                              setActiveSession={this.setActiveSession}/>
                {!spinner && <Divider style={{marginBottom: 40}}/>}
                <RegisterModal setActiveSession={this.setActiveSession} drawerVisible={this.state.drawerVisible}
                               updateParent={this.updateDrawerVisibility}/>
            </>
        )
    }
}

export default LoginAndSignUp;