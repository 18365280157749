import React, {Component} from 'react';
import LayoutJSX from "./components/layout/LayoutJSX";

class App extends Component {

    render() {
        return (
            <LayoutJSX/>
        )
    }
}

export default App;