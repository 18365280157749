import React from "react";
import {Tabs} from "antd";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {monokaiSublime as docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {JavaSnippet} from "./JavaSnippet";
import {GoSnippet} from "./GoSnippet";
import {PythonSnippet} from "./PythonSnippet";

const CodeSnippets = () => {

    const {TabPane: TP} = Tabs;

    return (
        <Tabs defaultActiveKey={"java"} tabPosition={"left"}>
            <TP key={"java"} tab={"Java"}>
                <SyntaxHighlighter language={"java"} style={docco}>
                    {JavaSnippet}
                </SyntaxHighlighter>
            </TP>
            <TP key={"go"} tab={"Go"}>
                <SyntaxHighlighter language={"go"} style={docco}>
                    {GoSnippet}
                </SyntaxHighlighter>
            </TP>
            <TP key={"python"} tab={"Python"}>
                <SyntaxHighlighter language={"python"} style={docco}>
                    {PythonSnippet}
                </SyntaxHighlighter>
            </TP>
        </Tabs>
    )
}
export {CodeSnippets}