import React, {useEffect, useState} from "react";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Button, DatePicker, Form, Input, TimePicker} from "antd";
import TabLayout from "../../utils/TabLayout";

const {RangePicker: DateRange} = DatePicker;
const {RangePicker: TimeRange} = TimePicker;

const CreateSlotComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    //Below is required instead of state to minimize writing methods to handle state changes
    const [slotId, setSlotId] = useState([0]);
    const [disableRemove, setDisableRemove] = useState(true);
    const [disableAdd, setDisableAdd] = useState(true);
    const [slotName, setSlotName] = useState(null);
    const [slotCap, setSlotCap] = useState(null);
    const [slotDate, setSlotDate] = useState(['','']);
    const [slotTime, setSlotTime] = useState([]);
    const [payload, setPayload] = useState({
        fromDate: '',
        toDate: '',
        name: '',
        capacityPerSlot: '',
        slots: [{}]
    });

    useEffect(() => {
        let keyRemoved = [];

        // eslint-disable-next-line array-callback-return
        slotTime.map(item => {
            keyRemoved = [...keyRemoved, {startTime: item.startTime, endTime: item.endTime, startTimeMinutes: item.startTimeMinutes, endTimeMinutes: item.endTimeMinutes}]
        });
        setPayload({
            fromDate: slotDate[0],
            toDate: slotDate[1],
            name: slotName,
            capacityPerSlot: slotCap,
            slots: keyRemoved
        })
    }, [slotId, slotDate, slotName, slotCap, disableRemove, disableAdd, slotTime])

    const addSlotTime = (key, time) => {
        let slotTimeClone = slotTime.filter(item => item.key !== key);
        const startTimeSplit = time[0].toString().split(":");
        const endTimeSplit = time[1].toString().split(":");
        const startTime = startTimeSplit[0];
        const endTime = endTimeSplit[0];
        const startTimeMinutes = startTimeSplit[1];
        const endTimeMinutes = endTimeSplit[1];
        setSlotTime([...slotTimeClone, {key: key, startTime, endTime, startTimeMinutes, endTimeMinutes}])
    }

    const Slot = slotId.map(key => {
        return (
            <TimeRange hideDisabledOptions={false} style={{marginBottom: 10}} key={key} format={"H:mm"}
                       onChange={(a, b) => addSlotTime(key, b)}
                       allowClear={false}/>
        )
    });

    const addSlot = () => {
        setSlotId([...slotId, slotId[slotId.length - 1] + 1]);
    }

    const removeSlot = (key) => {
        const updatedAvailableSlots = slotId.filter(item => item !== key);
        setSlotId([...updatedAvailableSlots]);
        let slotTimeClone = slotTime.filter(item => item.key !== key);
        setSlotTime([...slotTimeClone]);
    }

    useEffect(() => {
        if (slotId.length === 1) {
            setDisableRemove(true);
        }
        if (slotId.length === 2) {
            setDisableRemove(false);
        }
        if (slotId.length === slotTime.length) {
            setDisableAdd(false);
        }
        if (slotId.length > slotTime.length) {
            setDisableAdd(true);
        }

    }, [slotId, slotTime]);

    return (
        <TabLayout disableTabs={disableTabs} responseJson={responseJson} >
            <FormBasicComponent formLayout={"horizontal"} wrapperColH={5} endpoint={"slot/create"} payload={payload} push={push}
                                setDisableTabs={setDisableTabs} setResponseJson={setResponseJson} buttonText={"Create Slot Window"}
                                httpMethod={"POST"} >
                <Form.Item
                    label="Slot Name"
                    name="slotName"
                    rules={[{required: true, message: 'Please input a valid Slot Name!'}]}
                >
                    <Input id={"name"} allowClear={true} placeholder={"eg. Slot For Today"}
                           onChange={(i) => setSlotName(i.target.value)}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Slot Capacity"
                    name="capacityPerSlot"
                    rules={[{required: true, message: 'Please input a valid Slot Capacity!'}]}
                >
                    <Input id={"capacityPerSlot"} allowClear={true} placeholder={"eg. 1000"}
                           onChange={(i) => setSlotCap(i.target.value)}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Slot Date"
                    name="slotDate"
                    rules={[{required: true, message: 'Please input a valid Slot Window Date!'}]}
                >
                    <DateRange format={"D MMM, YYYY"} onChange={(a, b) => setSlotDate([b[0], b[1]])}
                               allowClear={false}/>
                </Form.Item>

                <Form.Item
                    label="Time Range"
                    name="timeRange"
                    rules={[{required: true, message: `Please input a time range!`}]}
                >
                    {Slot}
                </Form.Item>

                <Form.Item wrapperCol={{offset: 10, span: 8}}>
                    <Button type="default" onClick={addSlot}
                            style={{minWidth: 90, marginRight: 20}} disabled={disableAdd}>
                        Add Slot
                    </Button>
                    <Button type="default" onClick={() => removeSlot(slotId.length - 1)}
                            style={{minWidth: 90}} disabled={disableRemove}>
                        Remove Slot
                    </Button>
                </Form.Item>

            </FormBasicComponent>
        </TabLayout>
    )
}
export default CreateSlotComponent;