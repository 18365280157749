import React, {useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Form, Input} from "antd";

const ReleaseSlotComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [payload, setPayload] = useState({
        applicantEmailId: '', assessmentId: ''
    });

    return(
        <TabLayout responseJson={responseJson} disableTabs={disableTabs}>
            <FormBasicComponent endpoint={"slot/release"} formLayout={"horizontal"} labelColH={9} wrapperColH={6}
                                buttonText={"Release Slot"} setResponseJson={setResponseJson} setDisableTabs={setDisableTabs}
                                push={push} httpMethod={"POST"} payload={payload}>
                <Form.Item
                    label="Applicant Email"
                    name="applicantEmailId"
                    rules={[{required: true, message: `Please input an email!`}]}
                    initialValue={''}
                >
                    <Input id={"applicantEmailId"} allowClear={true} placeholder={"eg. something@something.com"}
                           type={"email"} onChange={(e) => setPayload({...payload, applicantEmailId: e.target.value})}
                           autoComplete={"off"}/>
                </Form.Item>

                <Form.Item
                    label="Assessment ID"
                    name="assessmentId"
                    rules={[{required: true, message: `Please input an Assessment ID!`}]}
                    initialValue={''}
                >
                    <Input id={"assessmentId"} allowClear={true} placeholder={"eg. 90210"}
                           type={"email"} onChange={(e) => setPayload({...payload, assessmentId: e.target.value})}
                           autoComplete={"off"}/>
                </Form.Item>

            </FormBasicComponent>
        </TabLayout>
    )
}
export default ReleaseSlotComponent;