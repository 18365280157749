import GetAllTimezones from "../components/timezone/GetAllTimezones";
import elementDos from "../components/Element2";
import SetTimezone from "../components/timezone/SetTimezone";
import UpdateSettings from "../components/account/UpdateSettings";
import GetAllTests from "../components/account/GetAllTests";
import RegisterApplicantComponent from "../components/applicants/register-applicants/RegisterApplicantComponent";
import LoginApplicant from "../components/applicants/login-applicant/LoginApplicant";
import Playground from "../components/playground/Playground";
import MapApplicantComponent from "../components/applicants/map-applicant/MapApplicantComponent";
import ApplicantsInSlotComponent from "../components/applicants/applicant-in-slot/ApplicantsInSlotComponent";
import ResultsAll from "../components/applicants/results/all/ResultsAll";
import ResultsEmail from "../components/applicants/results/email/ResultsEmail";
import ResultsAssessmentEmail from "../components/applicants/results/assessment-email/ResultsAssessmentEmail";
import CreateSlotComponent from "../components/slots/create/CreateSlotComponent";
import SlotWindowsComponent from "../components/slots/windows/SlotWindowsComponent";
import SlotDetailsComponent from "../components/slots/details/SlotDetailsComponent";
import BookSlotComponent from "../components/slots/book/BookSlotComponent";
import ReleaseSlotComponent from "../components/slots/release/ReleaseSlotComponent";
import DeleteWindowComponent from "../components/slots/windows/DeleteWindowComponent";
import {PlaygroundLanding} from "../components/playground/PlaygroundLanding";
import {CodeSnippets} from "../components/playground/CodeSnippets";
import {RequestGenerator} from "../components/playground/RequestGenerator";
import {LogoutApplicantComponent} from "../components/applicants/logout-applicant/LogoutApplicantComponent";

const RoutesReact = [
        {
            path: '/home',
            component: elementDos
        },
        {
            path: '/account/timezones',
            component: GetAllTimezones
        },
        {
            path: '/account/timezone/update',
            component: SetTimezone
        },
        {
            path: '/account/settings/update',
            component: UpdateSettings
        },
        {
            path: '/account/tests',
            component: GetAllTests
        },
        {
            path: '/applicant/register',
            component: RegisterApplicantComponent
        },
        {
            path: '/applicant/login',
            component: LoginApplicant
        },
        {
            path: '/applicant/logout',
            component: LogoutApplicantComponent
        },
        {
            path: '/playground',
            component: Playground
        },
        {
            path: '/applicant/map',
            component: MapApplicantComponent
        },
        {
            path: '/applicant/slot',
            component: ApplicantsInSlotComponent
        },
        {
            path: '/applicant/results/all',
            component: ResultsAll
        },
        {
            path: '/applicant/results/email',
            component: ResultsEmail
        },
        {
            path: '/applicant/results/test/email',
            component: ResultsAssessmentEmail
        },
        {
            path: '/slots/create',
            component: CreateSlotComponent
        },
        {
            path: '/slots/windows',
            component: SlotWindowsComponent
        },
        {
            path: '/slots/details',
            component: SlotDetailsComponent
        },
        {
            path: '/slots/book',
            component: BookSlotComponent
        },
        {
            path: '/slots/release',
            component: ReleaseSlotComponent
        },
        {
            path: '/slots/delete',
            component: DeleteWindowComponent
        },
        {
            path: '/utilities',
            component: PlaygroundLanding
        },
        {
            path: '/code-snippets',
            component: CodeSnippets
        },
        {
            path: '/signature',
            component: RequestGenerator
        }
    ]
export default RoutesReact;