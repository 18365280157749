import React, {useState} from "react";
import CreateResultView from "../../../utils/CreateResultView";
import TabLayout from "../../../utils/TabLayout";
import FormItemsComponents from "../../../utils/FormItemsComponent";
import {Form, Input} from "antd";

const ResultsEmail = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [email, setEmail] = useState('');

    let interpretation = null;

    if (responseJson.response.hasOwnProperty('applicants') && responseJson.response.applicants.length > 0) {
        interpretation = CreateResultView(responseJson);
    }

    return (
        <TabLayout responseJson={responseJson} disableTabs={disableTabs} interpretation={interpretation}>
            <FormItemsComponents setResponseJson={setResponseJson} setDisableTabs={setDisableTabs} push={push}
                                 marginLeftH={"0vw"} endpoint={"results/email"}
                                 httpMethod={"POST"} payload={{email}} >
                <Form.Item
                    label="Email ID"
                    name="emailId"
                    rules={[{required: true, message: `Please enter a valid Email ID!`}]}
                    initialValue={''}
                    style={{marginBottom: 8}}
                >
                    <Input id={"emailId"} style={{width: 250}} allowClear={true}
                           placeholder={"eg. something@something.com"}
                           type={"email"} onChange={(e) => setEmail(e.target.value)} autoComplete={"off"}/>
                </Form.Item>
            </FormItemsComponents>
        </TabLayout>
    )
}
export default ResultsEmail;