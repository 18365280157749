import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, message, Radio, Row, Select, Tabs} from "antd";
import {PlusOutlined, CloseOutlined} from "@ant-design/icons";
import AxiosBase from "../../configurations/AxiosBase";
import {env_var} from "../../environment";
import ReactJson from "react-json-view";

const RequestGenerator = (props) => {

    const [keys, setKeys] = useState([]);
    const [apiUrl, setApiUrl] = useState(``);
    const [method, setMethod] = useState(`GET`);
    const [ak, setAk] = useState(``);
    const [subdomain, setSubdomain] = useState(``);
    const [ts, setTs] = useState(Math.floor(Date.now() / 1000).toString());
    const [radio, setRadio] = useState('false');
    const [response, setResponse] = useState({});

    const prefix = env_var.PREFIX;
    const apiOpts = [
        {o: `Get Timezones`, u: `GET${prefix}getAllTimezones`},
        {o: `Set Timezones`, u: `POST${prefix}setAdminTimeZone`},
        {o: `Get Account Settings`, u: `GET${prefix}accountSettings`},
        {o: `Update Account Settings`, u: `POST${prefix}assessments/{assessment-id}/settings/edit`},
        {o: `Get All Tests`, u: `GET${prefix}tests`},
        {o: `Register Applicant`, u: `POST${prefix}registerApplicant`},
        {o: `Map Applicant`, u: `POST${prefix}applicants/certificate`},
        {o: `Login Applicant`, u: `POST${prefix}loginApplicant`},
        {o: `Get Applicants In Slot Window`, u: `GET${prefix}getAllCandidateEnrolledInSlotWindow`},
        {o: `Get All Results`, u: `GET${prefix}getAllApplicantResults`},
        {o: `Get Results By Email`, u: `POST${prefix}applicantResults`},
        {o: `Get Results By Email And Test`, u: `POST${prefix}applicantResultsOfAssessment`},
        {o: `Get Results By Test`, u: `GET${prefix}allApplicantResultsOfCertificate`},
        {o: `Create Slots`, u: `POST${prefix}createSlot`},
        {o: `Get All Slots`, u: `GET${prefix}getAllSlotWindows`},
        {o: `Get Slot Details`, u: `GET${prefix}getSlotDetails`},
        {o: `Book Slots For Applicant`, u: `POST${prefix}bookSlotsForApplicants`},
        {o: `Release Slots For Applicant`, u: `POST${prefix}releaseSlot`},
        {o: `Delete Slots`, u: `POST${prefix}deleteSlotWindow`}
    ]

    const apiMethod = [`GET`, `POST`].map(o => {
        return {label: o, value: o}
    });

    const addRow = () => {
        const newIndex = Math.floor(Date.now());
        setKeys([...keys, {id: newIndex, keyName: '', value: ``}]);
    }

    const removeRow = (key) => {
        setKeys(keys.filter(k => k.id !== key))
    }

    const findIndexById = (id) => {
        let index = 0;
        for (let i of keys) {
            if (i.id === id) {
                return index;
            }
            index++;
        }
    }

    const handleKey = (e, k) => {
        const index = findIndexById(k);
        let copy = [...keys];
        copy[index] = {
            ...copy[index],
            keyName: e.target.value
        }
        setKeys(copy);
    }

    const handleValue = (e, k) => {
        const index = findIndexById(k);
        let copy = [...keys];
        copy[index] = {
            ...copy[index],
            value: e.target.value
        }
        setKeys(copy);
    }

    const radioOpts = [
        {label: 'Generate Signature', value: "false"},
        {label: 'Invoke API', value: "true"}
    ]

    const handleSelect = (e) => {
        if (e.toString().startsWith(`GET`)) {
            setApiUrl(e.toString().replace(`GET`, ``));
            setMethod(`GET`)
        } else if (e.toString().startsWith(`POST`)) {
            setApiUrl(e.toString().replace(`POST`, ``));
            setMethod(`POST`)
        }
    }

    const createPayloadFromKeys = (keys) => {
        const map = {};
        keys.map(object => {
            map[object.keyName] = object.value
        });
        return map;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const map = createPayloadFromKeys(keys);

        const payload = {
            url: `https://` + apiUrl,
            method,
            request: {
                ak,
                subdomain,
                ts,
                ...map
            }
        }

        try {
            const promise = await AxiosBase().post(`playground?invokeMettlAPI=${radio}`, payload);

            if (promise.status === 200) {
                setResponse(promise.data);
                message.success("Please navigate to response tab to view details.")
            } else if (promise.status === 403) {
                message.error(`Please check if you have a valid session.`)
            }

        } catch (e) {
            message.error(`Please check your internet connection.`);
            console.log(e);
        }
    }

    useEffect(() => {
        const callAPI = async () => {
            try {
                const axiosResponse = await AxiosBase().get(`key/public`);
                if (axiosResponse.status === 200) {
                    setAk(axiosResponse.data.ak);
                    setSubdomain(axiosResponse.data.subdomain);
                } else if (axiosResponse.status === 403) {
                    message.error({duration: 3, content: `Please check that you have a valid active session.`})
                    setAk(`INVALID SESSION`);
                    setSubdomain(`INVALID SESSION`);
                }
            } catch (e) {
                message.error('Please check your internet connection.');
                setAk(`INVALID SESSION`);
                setSubdomain(`INVALID SESSION`);
            }
        };
        if (ak === ``)
            callAPI();
    }, [ak])

    return (
        <>
            <Tabs type={"card"} defaulActiveKey={'1'}>
                <Tabs.TabPane tab={"Request"} key={"request"}>
                    <Form layout={"vertical"}>
                        <Row gutter={[24, 0]} justify={"start"} align={"top"}>
                            <Col span={12} offset={1}>
                                <Form.Item label={"API URL"} name={"apiUrl"} getValueProps={() => {
                                    return {value: apiUrl}
                                }}>
                                    <Input addonBefore={"https://"} placeholder={"Enter the API URL here"}
                                           onChange={e => setApiUrl(e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={"Select API"} name={"selectAPI"}>
                                    <Select placeholder={"Select an API"} allowClear={true}
                                            onChange={e => handleSelect(e)}>
                                        {apiOpts.map(obj => <Select.Option value={obj.u}
                                                                           key={obj.o}>{obj.o}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 8]} justify={"start"} align={"top"}>
                            <Col span={4} offset={1}>
                                Parameters
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify={"start"} align={"top"}>
                            <Col span={4} offset={1}>
                                <Input value={"ak"} disabled/>
                            </Col>
                            <Col span={8}>
                                <Input value={ak} disabled/>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify={"start"} align={"top"}>
                            <Col span={4} offset={1}>
                                <Input value={"subdomain"} disabled/>
                            </Col>
                            <Col span={8}>
                                <Input value={subdomain} disabled/>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify={"start"} align={"top"}>
                            <Col span={4} offset={1}>
                                <Input value={"ts"} disabled/>
                            </Col>
                            <Col span={8}>
                                <Input value={ts} onChange={e => setTs(e.target.value)}/>
                            </Col>
                        </Row>
                        {keys.map(k => (
                            <Row key={k.id.toString()} gutter={[16, 16]} justify={"start"} align={"top"}>
                                <Col span={4} offset={1}>
                                    <Input placeholder={"Enter parameter"} onChange={e => handleKey(e, k.id)}/>
                                </Col>
                                <Col span={8}>
                                    <Input placeholder={"Enter value"} onChange={e => handleValue(e, k.id)}
                                           disabled={keys[findIndexById(k.id)].keyName === ``}/>
                                </Col>
                                <Col>
                                    <Button onClick={() => removeRow(k.id)} danger><CloseOutlined/></Button>
                                </Col>
                            </Row>
                        ))}
                        <Row gutter={[16, 16]} justify={"start"} align={"top"}>
                            <Col offset={1}>
                                <Button type={"default"} icon={<PlusOutlined/>} onClick={addRow}>ADD</Button>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]} justify={"start"} align={"bottom"}>
                            <Col span={3} offset={1}>
                                <Form.Item label={"Method"} name={"method"} getValueProps={() => {
                                    return {value: method}
                                }}>
                                    <Select options={apiMethod} onChange={e => setMethod(e)}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Radio.Group options={radioOpts} defaultValue={"false"} style={{marginBottom: 30}}
                                             onChange={e => setRadio(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]} justify={"start"} align={"top"}>
                            <Col span={3} offset={1}>
                                <Button type={"primary"} onClick={handleSubmit} disabled={ak === `INVALID SESSION`}>Generate
                                    Signature</Button>
                            </Col>
                        </Row>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Response"} key={"response"} disabled={!response.hasOwnProperty(`signature`)}>
                    {response.response !== null && <>
                        <Divider orientation="left">Response</Divider>
                        <ReactJson src={response.response} name={false} iconStyle={"circle"}/>
                    </>}
                    <Divider orientation="left">Request</Divider>
                    <ReactJson src={response.request} name={false} iconStyle={"circle"}/>
                    <Divider orientation="left">More Information</Divider>
                    <ReactJson src={{
                        method: response.httpMethod,
                        url: response.url,
                        signature: response.signature,
                        concatenated_string: response.concatenatedString
                    }} name={false} iconStyle={"circle"}/>
                </Tabs.TabPane>
            </Tabs>
        </>
    )

}
export {RequestGenerator}