import React from "react";
import {FormOutlined} from "@ant-design/icons";

const ResourceCard = ({icon: I, heading, children}) => {

    return (
        <>
            <div>
                <div style={{display: "flex", justifyContent: "center", alignContent: "center", marginBottom: 10}}>
                    <I style={{fontSize: 36}}/>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <h5 align={"center"} style={{fontWeight: 400, fontSize: "1rem", marginBottom: 2}}>{heading}</h5>
                    <p align={"center"}>{children}</p>
                </div>
            </div>
        </>
    );
}
export {ResourceCard}
ResourceCard.defaultProps = {
    icon: FormOutlined,
    heading: `Sample Heading`
}