export const initialize = {
    authorization: localStorage.getItem('authorizationToken')
}

//Reducer Creator Helper
const createReducer = (initialize, handlers) => {
    return function reducerHelper(state = initialize, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state
        }
    }
}

//Action constants
const Actions = {
    STORE_AUTHORIZATION: 'STORE_AUTHORIZATION'
}

//Actual Reducer
const Reducer = createReducer(initialize, {

        [Actions.STORE_AUTHORIZATION]: (state, action) => {
            state = {
                ...state,
                authorization: action.token
            }
            return state;
        }
    }
);
export default Reducer;
