import React from "react";
import {Row, Col} from "antd";
import {CloudDownloadOutlined, CodeOutlined, FormOutlined} from "@ant-design/icons";
import {ResourceCard} from "./ResourceCard";
import {Link} from "react-router-dom";

const PlaygroundLanding = () => {

    const columnStyle = {
        style: {
            height: 170,
            paddingTop: 20,
            background: `rgb(244, 246, 248)`
        }
    }

    const linkProperty = {
        style: {
            textDecoration: "none",
            color: "inherit",
            display: "block"
        }
    }

    const tiles = {
        xs: {
            span: 6
        },
        sm: {
            span: 5
        },
        md: {
            span: 7
        },
        xl: {
            span: 5
        }
    }

    return (
        <>
            <Row justify={"center"} gutter={[16, 16]} align={"bottom"} style={{height: 280}}>
                <Col {...columnStyle} {...tiles}>
                    <Link to={"/signature"} {...linkProperty}>
                    <ResourceCard heading={`Generate Signature`}>
                        Invoke CertiCheck APIs and view signature generation
                    </ResourceCard>
                    </Link>
                </Col>
                <Col {...columnStyle} {...tiles} offset={1}>
                    <Link to={"/code-snippets"} {...linkProperty}>
                    <ResourceCard heading={`Code Snippets`} icon={CodeOutlined}>
                        Browse code-snippets for signature generation
                    </ResourceCard>
                    </Link>
                </Col>
                <Col {...columnStyle} {...tiles} offset={1}>
                    <a href={"https://support.mettl.com/portal/en/kb/induslynk-training-services-p-ltd/client1/certification"} {...linkProperty} target={"_blank"} rel={"noopener noreferrer"}>
                    <ResourceCard heading={'Download Documentation'} icon={CloudDownloadOutlined}>
                        View and download PDF for documentation
                    </ResourceCard>
                    </a>
                </Col>
            </Row>
        </>
    )
}
export {PlaygroundLanding}