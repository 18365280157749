import React, {useState} from "react";
import TabLayout from "../../utils/TabLayout";
import {Badge, Divider, Form, InputNumber, Table} from "antd";
import {UserOutlined} from "@ant-design/icons";
import FormItemsComponents from "../../utils/FormItemsComponent";

const SlotDetailsComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [slotId, setSlotId] = useState(null);

    let tableData = [];
    if (responseJson.response.hasOwnProperty('slotDetails')) {
        // eslint-disable-next-line array-callback-return
        responseJson.response.slotDetails.candidateDetailsList.map(item => {
            tableData = [...tableData, {
                firstName: item.firstName,
                email: item.email,
                certificationName: item.certificationName,
                certificationAssessmentId: item.certificationAssessmentId,
                bookingTime: item.bookingTime
            }]
        })
    }


    const columns = [
        {
            title: 'Candidate Name',
            dataIndex: 'firstName',
            key: 'firstName',
            align: 'center'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'center'
        },
        {
            title: 'Test Name',
            dataIndex: 'certificationName',
            // dataIndex: ['certificates',[0],'certificationName'],
            key: 'testName',
            width: 190,
            align: 'center'
        },
        {
            title: 'Certification ID',
            dataIndex: 'certificationAssessmentId',
            // dataIndex: ['certificates',[0],'certificationName'],
            key: 'certificationAssessmentId',
            width: 115,
            align: 'center'
        },
        {
            title: 'Booking Time',
            dataIndex: 'bookingTime',
            // dataIndex: ['certificates',[0],'certificationName'],
            key: 'bookingTime',
            width: 190,
            align: 'center'
        },
    ];

    let createView;

    if (responseJson.response.hasOwnProperty('slotDetails')) {
        createView = (
            <>
                <Form.Item style={{marginLeft: "18vw"}}>
                    <Badge count={responseJson.response.slotDetails.enrolledCount === null ? 0 : responseJson.response.slotDetails.enrolledCount} showZero={true}>
                        <h3 style={{position: "relative", top: "5px", display: "inline-block"}}>
                            Candidates Enrolled: <UserOutlined style={{fontSize: "22px", position: "relative", bottom: "2px"}}/>
                        </h3>
                    </Badge>
                    <h3 style={{position: "relative", top: "5px", display: "inline-block", marginLeft: 60}}>Slot Window ID: {responseJson.response.slotDetails.slotWindowId}</h3>
                    <h3 style={{position: "relative", top: "5px", display: "inline-block", marginLeft: 60}}>Slot Window Name: {responseJson.response.slotDetails.slotWindowName}</h3>
                </Form.Item>
                <Divider/>
                <Table dataSource={tableData} columns={columns} size={"middle"} bordered scroll={{y: "36.5vh"}}/>
            </>)
    }

    return(
        <TabLayout disableTabs={disableTabs} responseJson={responseJson} interpretation={createView}>
                <FormItemsComponents push={push} setDisableTabs={setDisableTabs} setResponseJson={setResponseJson}
                                     buttonText={"Get Details"} marginLeftH={"0vw"} endpoint={`slot/${slotId}`} >
                    <Form.Item
                        label="Slot ID"
                        name="slotId"
                        rules={[{required: true, message: `Mandatory!`}]}
                        initialValue={''}
                    >
                        <InputNumber id={"slotId"} min={0} onChange={(v) => setSlotId(v)} autoComplete={"off"} placeholder={"ex. 1910"}/>
                    </Form.Item>
                </FormItemsComponents>
        </TabLayout>
    )
}
export default SlotDetailsComponent;