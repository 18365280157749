import React, {Component} from "react";
import {Tabs, Spin, Collapse, Row, Col, Statistic, Divider, Empty} from "antd";
import AxiosBase from "../../configurations/AxiosBase";
import ReactJson from "react-json-view";
import Notify from "../utils/Notifications";

const {TabPane} = Tabs;
const {Panel} = Collapse;

class GetAccountSettings extends Component {

    state = {
        correctResponse: false,
        disableTab: true,
        showJson: false,
        Json: {
            "emailSettings": {
                "sendSlotSelectionEmail": null,
                "sendSlotReminderEmail": null,
                "sendEmailOnAssigningTest": null
            },
            "applicantReportSettings": {
                "showReportToApplicant": null,
                "showResultOnTestCompletion": null
            },
            "applicantDashboardViewSettings": {
                "sortByName": null,
                "sortByPublishDate": null
            },
            "weekendSlotSetting": {
                "disableWeekendSlotting": null
            },
            "dataSecuritySettings": {
                "enableMettlSupportTeamAccess": null
            }
        }
    }

    async componentDidMount() {
        try {
            const promise = await AxiosBase().get('/account/settings');
            if (promise.status === 200) {
                if (promise.data.response.status !== `ERROR`) {
                    this.setState({
                        disableTab: false,
                        showJson: true,
                        correctResponse: true,
                        Json: promise.data.response,
                        request: promise.data.request,
                        moreInformation: {
                            "Request Method": promise.data.httpMethod,
                            "Concatenated String": promise.data.concatenatedString,
                            Signature: promise.data.signature,
                            URL: promise.data.url
                        }
                    });
                    Notify.defSuccessMessage()
                } else {
                    this.setState({
                        disableTab: false,
                        showJson: true,
                        correctResponse: false,
                        Json: promise.data.response,
                        request: promise.data.request,
                        moreInformation: {
                            "Request Method": promise.data.httpMethod,
                            "Concatenated String": promise.data.concatenatedString,
                            Signature: promise.data.signature,
                            URL: promise.data.url
                        }
                    });
                }
            } else {
                if (promise.status === 403){
                    this.props.history.push('/');
                    Notify.sessionErrorMessage()
                }else{
                    Notify.defErrorMessage(promise.status)
                }
            }
        } catch (e) {
            Notify.internetErrorMessage();
        }finally {
            this.setState({
                showJson: true
            })
        }
    }

    render() {
        const {showJson, correctResponse, Json, request, moreInformation, Json: {emailSettings, applicantReportSettings, applicantDashboardViewSettings, weekendSlotSetting, dataSecuritySettings}, disableTab} = this.state;
        const collapse = showJson ? correctResponse ? <Collapse defaultActiveKey={['1', '2']}>
            <Panel header="emailSettings" key="1">
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="sendSlotSelectionEmail"
                                   value={emailSettings.sendSlotSelectionEmail}/>
                    </Col>
                    <Col span={8}>
                        <Statistic title="sendSlotReminderEmail"
                                   value={emailSettings.sendSlotReminderEmail}/>
                    </Col>
                    <Col span={8}>
                        <Statistic title="sendEmailOnAssigningTest"
                                   value={emailSettings.sendEmailOnAssigningTest}/>
                    </Col>
                </Row>
            </Panel>
            <Panel header="applicantReportSettings" key="2">
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="showReportToApplicant"
                                   value={applicantReportSettings.showReportToApplicant}/>
                    </Col>
                    <Col span={8}>
                        <Statistic title="showResultOnTestCompletion"
                                   value={applicantReportSettings.showResultOnTestCompletion}/>
                    </Col>
                </Row>
            </Panel>
            <Panel header="applicantDashboardViewSettings" key="3">
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="sortByName"
                                   value={applicantDashboardViewSettings.sortByName}/>
                    </Col>
                    <Col span={8}>
                        <Statistic title="sortByPublishDate"
                                   value={applicantDashboardViewSettings.sortByPublishDate}/>
                    </Col>
                </Row> </Panel>
            <Panel header="weekendSlotSetting" key="4">
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="disableWeekendSlotting"
                                   value={weekendSlotSetting.disableWeekendSlotting}/>
                    </Col>
                </Row>
            </Panel>
            <Panel header="dataSecuritySettings" key="5">
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="enableMettlSupportTeamAccess"
                                   value={dataSecuritySettings.enableMettlSupportTeamAccess}/>
                    </Col>
                </Row>
            </Panel>
        </Collapse> : <Empty/>: <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>


        return (
            <>
                <Tabs type="card" defaultActiveKey="3">
                    <TabPane tab="Response" key="1" disabled={disableTab}>
                        {showJson ? <><ReactJson src={Json} name={false} iconStyle={"circle"}/></>
                            :
                            <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                    </TabPane>
                    <TabPane tab="Request" key="2" disabled={disableTab}>
                        {showJson ?
                            <>
                                <Divider orientation="left">Request Parameters</Divider>
                                <ReactJson src={request} name={false} iconStyle={"circle"}/>
                                <Divider orientation="left">More Information</Divider>
                                <ReactJson src={moreInformation} name={false} iconStyle={"circle"}/>
                            </> :
                            <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                    </TabPane>
                    <TabPane tab="View" key="3">
                        {collapse}
                    </TabPane>
                </Tabs>
            </>
        )
    }
}

export default GetAccountSettings;