import Axios from "axios";
import reduxStore from "../redux/ReduxStore";
import {env_var} from "../environment";

const AxiosBase = () => Axios.create({
    baseURL: env_var.API_BASE_URL + '/api/',
    headers: {
        authorization: reduxStore.getState().authorization
    },
    validateStatus: () => true
});
export default AxiosBase;