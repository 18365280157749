const PythonSnippet = `
def generate_asgn(params, method, url, private_key):
    concatenated = ""
    for param in params:
        concatenated = concatenated + "\\n" + params.get(param)
    concatenated_string = method + url + concatenated
    secret = bytes(private_key, "utf-8")
    data = bytes(concatenated_string, "utf-8")
    signature = base64.b64encode(hmac.new(secret, data, digestmod=hashlib.sha256).digest())
    return urllib.parse.quote_plus(signature)
`
export {PythonSnippet}