import React, {useState} from "react";
import {Button, Form, message, Popconfirm} from "antd";
import AxiosBase from "../../configurations/AxiosBase";
import Notify from "./Notifications";

const FormBasicComponent = ({formLayout, labelColH, wrapperColH, marginLeftH, httpMethod, children, endpoint, setResponseJson, setDisableTabs, push, payload, buttonText, useDefaultButton}) => {

    const [buttonLoading, setButtonLoading] = useState(false);

    const layout = (formLayout === 'horizontal') ? {
        labelCol: {span: labelColH},
        wrapperCol: {span: wrapperColH}
    } : {
        style: {marginLeft: marginLeftH}
    };

    const confirm = (e) => {
        makeRequest(e);
        message.info({
            content: 'Request to delete was sent!',
            style: {position: "relative", left: '7vw', top: '3vh'}
        });
    }

    const cancel = () => {
        message.error({
            content: 'Request to delete was not sent!',
            style: {position: "relative", left: '7vw', top: '3vh'}
        });
    }

    const makeRequest = async (e) => {
        e.preventDefault();

        setButtonLoading(true);

        try {
            let promise;
            switch (httpMethod) {
                case 'GET':
                    promise = await AxiosBase().get(endpoint);
                    break;
                case 'POST':
                    promise = await AxiosBase().post(endpoint, payload);
                    break;
                case 'DELETE':
                    promise = await AxiosBase().delete(endpoint);
                    break;
                default:
                    console.log(`%c Incorrect HTTP Method`, 'color: white; background-color: red');
                    break;
            }

            if (promise.status !== 200) {
                if (promise.status === 403) {
                    Notify.sessionErrorMessage();
                    push('/')
                    return
                }
                Notify.defErrorMessage(promise.status)
            } else {
                setResponseJson(promise.data);
                setDisableTabs(false);

                setButtonLoading(false);
                Notify.defSuccessMessage()
            }
        } catch (e) {
            Notify.internetErrorMessage();
        } finally {
            setButtonLoading(false)
        }
    }

    const tailLayout = (formLayout === 'horizontal') ? {
        wrapperCol: {offset: labelColH, span: wrapperColH}
    } : {
        style: {marginLeft: 20}
    };

    return (
        <Form
            {...layout}
            layout={formLayout}
            name="basic"
            initialValues={{remember: true}}
            onFinish={makeRequest}
            onFinishFailed={() => console.log('%c Form finish failed!', 'color: red')}
        >

            {children}

            {useDefaultButton ?
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit"
                            style={{minWidth: 90}} loading={buttonLoading} onClick={makeRequest}>
                        {buttonText}
                    </Button>
                </Form.Item> :
                <Form.Item {...tailLayout}>
                    <Popconfirm
                        title="Are you sure you want to delete this slot window?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" htmlType="submit" loading={buttonLoading}
                                style={{minWidth: 90}}>
                            {buttonText}
                        </Button>
                    </Popconfirm>
                </Form.Item>}
        </Form>
    )
}
export default FormBasicComponent;
FormBasicComponent.defaultProps = {
    formLayout: 'inline',
    labelColH: 10,
    wrapperColH: 8,
    httpMethod: 'GET',
    endpoint: 'ping',
    payload: {},
    marginLeftH: '22vw',
    buttonText: 'Invoke',
    useDefaultButton: true
}