import React, {useEffect, useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Form, Select} from "antd";
import AxiosBase from "../../../configurations/AxiosBase";
import Notify from "../../utils/Notifications";

const {Option} = Select;

const DeleteWindowComponent = ({history:{push}}) => {

    const [responseJson, setResponseJson] = useState({response: {}, request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);

    const [slotWindowId, setSlotWindowId] = useState(null);
    const [allSlotWindows, setAllSlotWindows] = useState([]);
    const [slotWindowSearching, setSlotWindowSearching] = useState(true);

    const getWindows = async () => {
        setSlotWindowSearching(true)
        try {
            const promise = await AxiosBase().get('slot/windows?limit=500&offset=0');
            if (promise.status === 200) {
                setAllSlotWindows(promise.data.response.slotWindows.map(item => {
                    return (
                        <Option key={item.slotWindowId}
                                value={item.slotWindowId}>{`${item.slotWindowId} - ${item.name}`}</Option>
                    )
                }, setSlotWindowSearching(false)));
            } else {
                if (promise.status === 403) {
                    Notify.sessionErrorMessage();
                    return
                }
                Notify.defErrorMessage(promise.status);
            }
        } catch (e) {
            Notify.internetErrorMessage();
        }finally {
            setSlotWindowSearching(false);
        }
    }

    useEffect(() => {
        getWindows();
    }, [responseJson])

    return(
        <TabLayout disableTabs={disableTabs} responseJson={responseJson}>
            <FormBasicComponent httpMethod={"DELETE"} formLayout={"horizontal"} wrapperColH={6} labelColH={9}
                                setDisableTabs={setDisableTabs} setResponseJson={setResponseJson}
                                push={push} buttonText={"Delete Slot Window"} endpoint={`slot/window/${slotWindowId}`} useDefaultButton={false}>
                <Form.Item
                    label="Slot Window ID"
                    name="slotWindowId"
                    rules={[{required: true, message: `Please input a Slot Window ID!`}]}
                >
                    <Select placeholder={"ex. 90210"}  loading={slotWindowSearching} showSearch listHeight={400}
                            onChange={(swid) => setSlotWindowId(swid)}>
                        {allSlotWindows}
                    </Select>
                </Form.Item>

            </FormBasicComponent>
        </TabLayout>
    )
}
export default DeleteWindowComponent;