import React, {useState} from "react";
import {Divider, Tabs} from "antd";
import ReactJson from "react-json-view";
import UpdateSettingsForm from "./UpdateSettingsForm";

const {TabPane} = Tabs;

const UpdateSettings = ({history: {push}}) => {

    const [disableTabs, setDisabledTabs] = useState(true);
    const [response, setResponse] = useState({});
    const [request, setRequest] = useState({});
    const [moreInformation, setMoreInformation] = useState({});

    const controlTabs = (tabsVisibility) => {
        setDisabledTabs(tabsVisibility);
    }

    const updateHooks = (dateFromPromise) => {
        setResponse(dateFromPromise.response);
        setRequest(dateFromPromise.request);
        setMoreInformation({
            "Request Method": dateFromPromise.httpMethod,
            "Concatenated String": dateFromPromise.concatenatedString,
            Signature: dateFromPromise.signature,
            URL: dateFromPromise.url
        });
    }

    return (
        <>
            <Tabs type="card" defaultActiveKey="3">
                <TabPane tab="Response" key="1" disabled={disableTabs}>
                    <ReactJson src={response} name={false} iconStyle={"circle"}/>
                </TabPane>
                <TabPane tab="Request" key="2" disabled={disableTabs}>
                    <Divider orientation="left">Request Parameters</Divider>
                    <ReactJson src={request} name={false} iconStyle={"circle"}/>
                    <Divider orientation="left">More Information</Divider>
                    <ReactJson src={moreInformation} name={false} iconStyle={"circle"}/>
                </TabPane>
                <TabPane tab="View" key="3">
                    <UpdateSettingsForm tabVisibility={controlTabs} hookData={updateHooks} pushToPath={push}/>
                </TabPane>
            </Tabs>
        </>
    )
}
export default UpdateSettings;