import React, {Component} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Checkbox, Form, Input, message, Spin} from "antd";
import AxiosBase from "../../../configurations/AxiosBase";

class RegisterApplicantComponent extends Component {

    state = {
        responseJson: {response: {}, request: {}, URL: {url: ''}},
        disableTabs: true,
        disableEmail: true,
        disableSSO: false,
        payload: {
            sendEmail: false,
            isAdminVerified: 'true',
            registrationDetails: []
        },
        CRF: [],
        spinner: true
    }

    checkboxChange = (e) => {

        switch (e.target.id) {
            case 'isAdminVerified':
                if (e.target.checked) {
                    this.setState({
                        disableEmail: true,
                        checked: false
                    })
                } else {
                    this.setState({
                        disableEmail: false
                    })
                }
                break;
            case 'sendEmail':
                this.setState({
                    checked: !this.state.checked,
                    disableSSO: false
                });
                if (e.target.checked) {
                    this.setState({
                        disableSSO: true
                    });
                }
                break;
            default:
                console.log(`Nothing to do`);
                break;
        }
        this.setState({
            payload: {
                ...this.state.payload,
                [e.target.id]: e.target.id === 'isAdminVerified' ? e.target.checked.toString() : e.target.checked
            }
        });
    }

    inputChange = (e) => {

        this.setState({
            payload: {
                ...this.state.payload,
                registrationDetails: [
                    {
                        ...this.state.payload.registrationDetails[0],
                        [e.target.id]: e.target.value
                    }
                ]
            },
            disableTabs: true
        });
    }

    async componentDidMount() {

        try {
            const promise = await AxiosBase().get(`mettl/CRF`);
            if (promise.status === 200) {
                this.setState({
                    CRF: promise.data
                })
            } else if (promise.status === 403) {
                message.error("Invalid session found. Please re-register.")
            }

        } catch (e) {

        } finally {
            this.setState({
                spinner: false
            })
        }
    }

    render() {
        const {disableEmail, disableSSO, checked, responseJson, disableTabs, payload, CRF, spinner} = this.state;
        const {history: {push}} = this.props;
        return (
            <TabLayout responseJson={responseJson} disableTabs={disableTabs} showDivider={!spinner}>

                {spinner && <Spin size="large" style={{marginLeft: "50%", marginTop: "15%"}}/>}
                {!spinner &&
                <FormBasicComponent endpoint={"register"} buttonText={"Register Applicant"} push={push}
                                    setResponseJson={(json) => this.setState({responseJson: json})} httpMethod={"POST"}
                                    setDisableTabs={(disableTabs) => this.setState({disableTabs: disableTabs})}
                                    formLayout={"horizontal"} labelColH={8} wrapperColH={8} payload={payload}>

                    {CRF.map(crfs =>
                        <Form.Item
                            key={crfs.name}
                            label={crfs.name}
                            name={crfs.name}
                            rules={[{required: true, message: `Please input your ${crfs.name}!`}]}
                        >
                            <Input id={crfs.name} allowClear={true}
                                   type={"text"} onChange={this.inputChange} autoComplete={"off"}/>
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Context Data"
                        name="contextData"
                        rules={[{required: false, message: 'Please enter context data!'}]}
                    >
                        <Input id={"contextData"} allowClear={true} onChange={this.inputChange} autoComplete={"off"}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8, span: 8}} name="flags" valuePropName="checked">
                        <Checkbox id={"isAdminVerified"} disabled={disableSSO} onChange={this.checkboxChange}
                                  defaultChecked>SSO (URL Login)</Checkbox>
                        <Checkbox id={"sendEmail"} disabled={disableEmail} checked={checked}
                                  onChange={this.checkboxChange}>Send
                            Email</Checkbox>
                    </Form.Item>
                </FormBasicComponent>
                }
            </TabLayout>
        )
    }
}

export default RegisterApplicantComponent;