import React, {useEffect, useState} from "react";
import TabLayout from "../../utils/TabLayout";
import FormBasicComponent from "../../utils/FormBasicComponent";
import {Form, Input} from "antd";
import Text from "antd/es/typography/Text";

const LogoutApplicantComponent = ({history: {push}}) => {

    const [responseJson, setResponseJson] = useState({response: '', request: {}, URL: {url: ''}});
    const [disableTabs, setDisableTabs] = useState(true);
    const [payload, setPayload] = useState({email: ''})
    const handleChange = (e) => {
        e.preventDefault();
        setPayload({
            email: e.target.value
        })
    }

    return (
        <TabLayout responseJson={responseJson} disableTabs={disableTabs} responseTab={false} reactJson={<Text copyable>{responseJson.response}</Text>}>
            <FormBasicComponent push={push} httpMethod={"POST"} endpoint={"logout"} payload={payload}
                                setDisableTabs={setDisableTabs} setResponseJson={setResponseJson}
                                formLayout={"horizontal"}
                                buttonText={'Logout Applicant'} labelColH={9} wrapperColH={6}>
                <Form.Item
                    label="Email ID"
                    name="emailId"
                    rules={[{required: true, message: 'Please input the Email ID!'}]}
                >
                    <Input id={"emailId"} allowClear={true} placeholder={"eg. something@something.com"}
                           type={"email"} onChange={handleChange} autoComplete={"off"}/>
                </Form.Item>
            </FormBasicComponent>
        </TabLayout>
    )
}
export {LogoutApplicantComponent}