import React, {Component} from "react";
import { Tabs, Typography} from "antd";
import ReactJson from "react-json-view";
import PlaygroundView from "./PlaygroundView";

const {TabPane} = Tabs;
const {Text} = Typography;

class Playground extends Component{

    state = {
        disableTab: true,
        response: {
            sampleKey1: 'sampleValue1',
            sampleKey2: false,
            sampleKey3: 123
        }
    }

    disableTab = (visibilityState) => {
        this.setState({
            disableTab: visibilityState
        });
    }

    updateResponse = (json) => {
        this.setState({
            response: json.data
        })
    }

    render() {

        const { disableTab, response } = this.state;

        return(
            <>
            <Tabs type="card" defaultActiveKey="1">
                <TabPane tab="Create" key="1" >
                    <PlaygroundView disableTab={this.disableTab} updateResponse={this.updateResponse}/>
                </TabPane>
                <TabPane tab="View" key="2" disabled={disableTab}>
                    {typeof response === 'object' ? <ReactJson src={response} name={false} iconStyle={"circle"} /> : <Text copyable>{response}</Text>}
                </TabPane>
            </Tabs>

        </>
        )
    }
}
export default Playground;