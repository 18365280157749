import React, {Component} from "react";
import './LayoutStyles.css';
import {connect} from "react-redux";

import {Layout, Menu} from 'antd';
import {UserOutlined, PieChartOutlined, TeamOutlined, ClockCircleOutlined} from '@ant-design/icons';

import {Switch, Route, Link} from 'react-router-dom';

import notFound from "../NotFound";
import SessionModal from "./SessionModal";
import LoginAndSignUp from "../Element1";
import RegisterModal from "./RegisterModal";
import GetAccountSettings from "../account/GetAccountSettings";
import ResultsAssessment from "../applicants/results/assessment/ResultsAssessment";
import RoutesReact from "../../configurations/RoutingConfiguration";

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

class LayoutJSX extends Component {

    state = {
        endpoint: '/home',
        method: 'POST',
        visible: false,
        drawerVisible: false,
        alertVisible: false,
        navSelectedKey: ''
    }

    theme = {
        styleIcons: {
            style:
                {fontSize: "18px"}
        }
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    toggleModal = (val) => {
        this.setState({
            visible: val
        })
    }

    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    updateDrawerVisibility = (val) => {
        this.setState({
            drawerVisible: val
        })
    }

    updateBreadcrumb = (method, endpoint) => {
        this.setState({
            method: method,
            endpoint: endpoint
        })
    }

    deselectNavKeys = () => {
        this.setState({
            navSelectedKey: ''
        })
    }

    selectNavKey = (e) => {
        this.setState({
            navSelectedKey: e.key
        })
    }

    setPath = (path) => {
        this.setState({
            method: path.method,
            endpoint: path.endpoint
        })
    }

    render() {
        const {styleIcons: css} = this.theme;

        const ReactRoutes = RoutesReact
            .map(({path, component: C}) =>
                <Route
                    key={path}
                    path={path}
                    render={(props) => <C {...props} setPath={this.setPath}/>}/>)
        return (
            <Layout>
                <Header className="header">
                    <div className="logo">
                    <img align={"right"} style={{marginTop:15, height: 30}} src={"https://mettl.com/prelogin/resources/images/Mercer_Mettl Logo Lockups_Website-03.png"}/> </div>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[this.state.navSelectedKey]}
                          onClick={this.selectNavKey}>
                        <Menu.Item key="1" onClick={this.showDrawer}>Create Session</Menu.Item>
                        {/*<Menu.Item key="2" onClick={this.showModal}>Create Session</Menu.Item>*/}
                        <Menu.Item key="2"><Link to="/utilities">Utilities</Link></Menu.Item>
                    </Menu>
                </Header>
                <Layout>
                    <Sider width={"224px"} className="site-layout-background" style={{marginTop: 20}}>
                        <Menu
                            mode="inline"
                            style={{height: '100%', borderRight: 0}}
                            onClick={this.deselectNavKeys}
                        >
                            <SubMenu key="sub1" icon={<ClockCircleOutlined {...css}/>} title="Timezone">
                                <Menu.Item key="1"><Link to="/account/timezones">Get Timezones</Link></Menu.Item>
                                <Menu.Item key="2"><Link to="/account/timezone/update">Set Timezone</Link></Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub2" icon={<UserOutlined {...css}/>} title="Account">
                                <Menu.Item key="5"><Link to="/account/settings">Get Settings</Link></Menu.Item>
                                <Menu.Item key="6"><Link to="/account/settings/update">Update Settings</Link></Menu.Item>
                                <Menu.Item key="7"><Link to="/account/tests">Get All Tests</Link></Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub3" icon={<TeamOutlined {...css}/>} title="Applicants">
                                <Menu.Item key="9"><Link to="/applicant/register">Register Applicant</Link></Menu.Item>
                                <Menu.Item key="10"><Link to="/applicant/map">Map Applicant</Link></Menu.Item>
                                <Menu.Item key="11"><Link to="/applicant/login">Login Applicant</Link></Menu.Item>
                                <Menu.Item key="23"><Link to="/applicant/logout">Logout Applicant</Link></Menu.Item>
                                <Menu.Item key="12"><Link to="/applicant/slot">Applicant In Slot</Link></Menu.Item>
                                <Menu.Item key="13"><Link to="/applicant/results/all">All Results</Link></Menu.Item>
                                <Menu.Item key="14"><Link to="/applicant/results/email">Result By
                                    Email</Link></Menu.Item>
                                <Menu.Item key="15"><Link to="/applicant/results/test/email">Result By Email &
                                    Test</Link></Menu.Item>
                                <Menu.Item key="16"><Link to="/applicant/results/test">Result By
                                    Test</Link></Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub4" icon={<PieChartOutlined {...css}/>} title="Slotting">
                                <Menu.Item key="17"><Link to="/slots/create">Create Slot</Link></Menu.Item>
                                <Menu.Item key="18"><Link to="/slots/windows">All Slots</Link></Menu.Item>
                                <Menu.Item key="19"><Link to="/slots/details">Slot Details</Link></Menu.Item>
                                <Menu.Item key="20"><Link to="/slots/book">Book Slot</Link></Menu.Item>
                                <Menu.Item key="21"><Link to="/slots/release">Release Slot</Link></Menu.Item>
                                <Menu.Item key="22"><Link to="/slots/delete">Delete Slot</Link></Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>
                    <Layout style={{padding: '0px 24px 24px'}}>
                        {/*<Breadcrumb style={{margin: '10px 20px 0px 40px'}} separator={'|'}>
                            <Breadcrumb.Item>
                                <strong>Method:</strong> {this.state.method}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <strong>Endpoint:</strong> {this.state.endpoint}
                            </Breadcrumb.Item>
                        </Breadcrumb>*/}
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                marginTop: 20,
                                height: "calc(100vh - 110px)",
                                overflowY: "auto"
                            }}
                        >
                            <SessionModal toggleVisibility={this.state.visible} changeState={this.toggleModal}/>
                            <RegisterModal drawerVisible={this.state.drawerVisible}
                                           updateParent={this.updateDrawerVisibility}/>
                            <Switch>
                                <Route exact path='/' component={LoginAndSignUp}/>
                                <Route exact path='/account/settings' component={GetAccountSettings}/>
                                <Route exact path={'/applicant/results/test'} component={ResultsAssessment}/>
                                {ReactRoutes}
                                <Route component={notFound}/>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

const sendData = (dispatch) => {
    return {
        storeJWT: (payload) => {
            dispatch({
                type: 'STORE_AUTHORIZATION', token: payload
            });
        }
    }
}
export default connect(null, sendData)(LayoutJSX);
